import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiRoutes } from '../config/api-routes';
import 'rxjs/Rx';
import { Storage } from '../utilities/storage';
import { Location } from '../../../../node_modules/@angular/common';
import { Helper } from '../utilities/helpers';
import settings from '../config/settings';


@Injectable()
export class AuthenticationService {

    constructor(
        private http: HttpClient,
        private apiRoutes: ApiRoutes,
        public storage: Storage,
        public location: Location
    ) { }

    login(credentials, params = {}) {
        let url = this.apiRoutes.loginUrl + Helper.getQueryParamsString(params);
        return this.http.post(url, {
            email: Helper.getObjProp(credentials, 'email'),
            password: Helper.getObjProp(credentials, 'password'),
            authType: 'business'
        })
            .map((res: any) => {
                return res.data;
            });
    }

    logout() {
        let formData = {
            fcm_token: this.storage.get('adminFcmToken')
        }
        return this.http.post(this.apiRoutes.getLogoutUrl, formData).finally(
            () => {
              let localObjects = settings.localStorage.objectNames;
              localObjects.forEach(element => {
                  this.storage.remove(element);
              });
            }).map((res: any) => { 
                return true;
            });
       
        // location.reload();
    }

    forgotPassword(formInputs) {
        return this.http.post(this.apiRoutes.getForgotPasswordUrl(), formInputs)
            .map((res: any) => {
                return res;
            });
    }

    resetPassword(formInputs){
        return this.http.post(this.apiRoutes.getResetPasswordUrl(), formInputs)
            .map((res: any) => {
                return res;
            });
    }

    get authUser() {
        return this.storage.get('authUser');
    }

    // Get Auth Token
    get getAuthToken() {
        let authUser = this.storage.get('authUser');
        let token = authUser.token;
        return 'Bearer ' + token;
    }
}