import {Component, OnInit, ViewChild} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Toastr } from '../../../plugins/toastr/toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidator } from 'src/app/core/utilities/validator/custom-validator';
import { Helper } from 'src/app/core/utilities/helpers';
import { UserService } from 'src/app/core/services/user.services';
import { Observable } from 'rxjs';
import { CollegeService } from 'src/app/core/services/college.service';
import { SelectComponent } from 'ng2-select';

@Component({
  selector: 'broadcast-modal',
  templateUrl: './broadcast.component.html',
  providers: [ UserService, Toastr, CollegeService ]
})
export class BroadcastModal implements OnInit {

  @ViewChild('examSelect') private examSelect: SelectComponent;
  @ViewChild('locationSelect') private locationSelect: SelectComponent;


  public messageForm: FormGroup;
  public loading: boolean = false;
  studentId: any;
  collegeId: any;
  currentResult: any;
  userTypes: any = ["student", "counsellor", "counsellor_manager", "writer", "writer_manager"];
  public courses: any;
  public selectedCourses: any = [];
  public locations: any;
  public selectedLocations: any = [];
  public timer: any = 0;
  public isCollapsedExam: boolean = false;
  public isCollapsedLocation: boolean = false;

  constructor(
    public userService: UserService,
    public toastr: Toastr, 
    public modalRef: BsModalRef,
    private formBuilder: FormBuilder,
    public collegeService: CollegeService
    ) {
  }


  ngOnInit(){
    this._fetchCourseFromServer();
    this._fetchLocationsFromServer();
    this._initMessageForm();
    console.log(this.studentId);
  }

  // =================================

// Courses Methods

// =================================



_selectedCourse(value: any): void {
  
  if (this.userTypes.includes('student')) {
    let tempArray = [];
    tempArray = this.userTypes;
    this.userTypes = [];
    tempArray.forEach(element => {
      if(element == 'student'){
        //Do nothing 
      }else{
        this.userTypes.push(element);
      }
    });
  }
  
  this.selectedCourses.push(value.id);
  
	// this.basicDetailsForm.patchValue({

	// 	course_types : this.selectedCourses,

  // });


}



_removedCourse(value: any): void {

  let tempArray = [];
  this.selectedCourses.forEach(element => {
    if(element == value.id){
      // Do nothing
    }else {
      tempArray.push(element);
    }
  });
  this.selectedCourses = tempArray;

	// this.basicDetailsForm.patchValue({

	// 	course_types : this.selectedCourses,

	// });


}



_typedCourse(value: any): void {

	clearTimeout(this.timer);



	this.timer = setTimeout(() => {

			let obj = {

					query: value

			};

			this._fetchCourseFromServer(obj);

	}, 2000);

}



_refreshCourseValue(value: any): void { }



_fetchCourseFromServer(paramsObj: Object = {}) {

	paramsObj = Helper.cloneObj(paramsObj, {

			limit: 100,

	});

  this.collegeService.getAllCoursetypes(paramsObj)

			.subscribe(

					(res) => {

							let data = Helper.getObjProp(res, 'data');

							let obj = [];

							for (let i = 0; i < data.length; i++) {

									obj.push({

											id: data[i].id,

											text: `${data[i].name}`

									});

							}

							this.courses = Observable.of(obj).map(res => res);
              console.log(this.courses);
					},

					(error) => {

							Helper.processErrorResponse(error, this.toastr);

							this.courses = Observable.never();

					}

			);  

  }


// =================================

// Locations Methods

// =================================



_selectedLocation(value: any): void {
  
  if (this.userTypes.includes('student')) {
    let tempArray = [];
    tempArray = this.userTypes;
    this.userTypes = [];
    tempArray.forEach(element => {
      if(element == 'student'){
        //Do nothing 
      }else{
        this.userTypes.push(element);
      }
    });
  }
  
  this.selectedLocations.push(value.id);
  
	// this.basicDetailsForm.patchValue({

	// 	course_types : this.selectedCourses,

  // });


}



_removedLocation(value: any): void {

  let tempArray = [];
  this.selectedLocations.forEach(element => {
    if(element == value.id){
      // Do nothing
    }else {
      tempArray.push(element);
    }
  });
  this.selectedLocations = tempArray;

	// this.basicDetailsForm.patchValue({

	// 	course_types : this.selectedCourses,

	// });


}



_typedLocation(value: any): void {

	clearTimeout(this.timer);



	this.timer = setTimeout(() => {

			let obj = {

					query: value

			};

			this._fetchLocationsFromServer(obj);

	}, 2000);

}



_refreshLocationsValue(value: any): void { }



_fetchLocationsFromServer(paramsObj: Object = {}) {

	paramsObj = Helper.cloneObj(paramsObj, {

			limit: 100,

	});

  this.collegeService.getAllLocations(paramsObj)

			.subscribe(

					(res) => {

							let data = Helper.getObjProp(res, 'data');

							let obj = [];

							for (let i = 0; i < data.length; i++) {

									obj.push({

											id: data[i].id,

											text: `${data[i].name}`

									});

							}

							this.locations = Observable.of(obj).map(res => res);
              console.log(this.locations);
					},

					(error) => {

							Helper.processErrorResponse(error, this.toastr);

							this.locations = Observable.never();

					}

			);  

  }

  _initMessageForm() {
		this.messageForm = this.formBuilder.group({
			message: ['', [
				Validators.required,
				CustomValidator.lessThenLength(254),
			]]
		});
	}

  submit(){
    if(this.userTypes.length == 0 && this.selectedLocations == 0 && this.selectedCourses == 0 ){
      this.toastr.showWarning('Kindly select atleast one user group for broadcasting message');
      return;
    }
    if(this.messageForm.invalid){
      return
    }

    let formData = {};

    if(this.selectedCourses.length != 0 && this.selectedLocations.length != 0){
      formData = Helper.cloneObj(this.messageForm.value, {
        user_types: this.userTypes,
        filter: {
          student: {
            exam_type: this.selectedCourses,
            location: this.selectedLocations
          }
        }
      });
    }else if(this.selectedCourses.length != 0 && this.selectedLocations == 0){
      formData = Helper.cloneObj(this.messageForm.value, {
        user_types: this.userTypes,
        filter: {
          student: {
            exam_type: this.selectedCourses
          }
        }
      });
    }else if(this.selectedCourses.length == 0 && this.selectedLocations != 0){
      formData = Helper.cloneObj(this.messageForm.value, {
        user_types: this.userTypes,
        filter: {
          student: {
            location: this.selectedLocations
          }
        }
      });
    }else{
      formData = Helper.cloneObj(this.messageForm.value, {
        user_types: this.userTypes
      });
    }
    this._startLoading();
    this.userService.postBroadcastMessage(formData).subscribe(
      res => {
        this.toastr.showSuccess('Successfully broadcasted message.');
        this.modalRef.hide();
        this._stopLoading();
      },
      error => {
        this._stopLoading();
      }
    );
  }

  checkStudentCondition(){
    if(this.selectedCourses.length != 0 || this.selectedLocations != 0){
      return true;
    }
    return false;
  }

  checkCoursesCondition(){
    if(this.selectedCourses.length != 0){
      return true;
    }
    return false;
  }

  checkLocationCondition(){
    if(this.selectedLocations != 0){
      return true;
    }
    return false;
  }

  check(e){
    if(e.target.value == 'student'){
      this.selectedLocations = [];
      this.selectedCourses = [];
      this.examSelect.active = [];
      this.locationSelect.active = [];
    }
    if(e.target.checked){
      this.userTypes.push(e.target.value);
      console.log(this.userTypes);
    }else{
      let tempArray = this.userTypes;
      this.userTypes = [];
      tempArray.forEach(element => {
        if(e.target.value == element){
          //do nothing
        }else{
          this.userTypes.push(element);
        }
      });
      console.log(this.userTypes);
    }
  }

  _startLoading() {
    this.loading = true;
  }
  
  _stopLoading() {
    this.loading = false;
  }

}
