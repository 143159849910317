import {Component, TemplateRef, OnInit} from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { UserService } from '../../../services/user.services';
import { Toastr } from '../../../plugins/toastr/toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Helper } from '../../../utilities/helpers';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'change-writer-modal',
  templateUrl: './change-writer.component.html',
  providers: [ Toastr, UserService ]
})
export class ChangeWriterModal implements OnInit {

  public studentId: any;
  public document: any;
  public selectedWriter: any = '';
  public writers: any = [];
  public loading: boolean  = false;
  public associateForm: FormGroup;
  public submitted: boolean = false;
  public authUser: any;
  public paginationData: any;
	public total: number;
	public page: number = 0;
  public per_page: number = 0;
  public currentWriterId: any;
  
  constructor(
    public modalRef: BsModalRef, 
    public userService: UserService, 
    public toastr: Toastr,
    private formBuilder: FormBuilder,
    public router: Router,
		public authService: AuthenticationService,
    ) {
    
  }

  ngOnInit() {
    this._initAssociateCounsellorForm();
		this._fetchAuthUser();
    }
    
    _fetchAuthUser(){
      this.authUser = this.authService.authUser;
      if (this.authUser.type == 'writer-manager') {
        this.getAllWritersByWriterManager();
      }else{
        this.getAllWriters();
      }
    }

  getAllWriters(page: number = 1, params = {}){
    console.log('Current Writer :::::::::', this.currentWriterId);
    this._startLoading();
    params = Helper.cloneObj(params, {
      page: page
		});
    this.writers = [];
    this.userService.getAllWriters(params).subscribe(
      res => {
        let index = 0;
        this.paginationData = res.meta.pagination;
				this.total = this.paginationData.total;
				this.per_page = this.paginationData.per_page;
				this.page = this.paginationData.current_page;
       res.data.forEach(element => {
        index = index + 1;
        // console.log(element.id)
        if(element.slug != this.currentWriterId){
          let tempObject = {
            id: index,
            writerId: element.slug,
            name: element.full_name
           };
  
           this.writers.push(Helper.cloneObj(element, tempObject));
        }
       });
       this._stopLoading();
      },
      error => {
        this._stopLoading();
        console.log(error);
      });
  }

  getAllWritersByWriterManager(page: number = 1, params = {}){
    this._startLoading();
    params = Helper.cloneObj(params, {
      page: page
		});
    this.writers = [];
    this.userService.getWriterManagerAllWriters(params).subscribe(
      res => {
        let index = 0;
        this.paginationData = res.meta.pagination;
				this.total = this.paginationData.total;
				this.per_page = this.paginationData.per_page;
				this.page = this.paginationData.current_page;
       res.data.forEach(element => {
        index = index + 1;
        if(element.slug != this.currentWriterId){
          let tempObject = {
            id: index,
            writerId: element.slug,
            name: element.full_name
           };
  
           this.writers.push(Helper.cloneObj(element, tempObject));
        }
       });
       this._stopLoading();
      },
      error => {
        this._stopLoading();
        console.log(error);
      });
  }

  gotoWriter(writerId){
    if (this.authUser.type == 'writer-manager') {
     this.router.navigate(['/writer-manager/writers/individual-writer/'+writerId+'/writer-profile']);
     this.modalRef.hide();
    }else{
      this.router.navigate(['/manager/writers/individual-writer/'+writerId+'/writer-profile']);
      this.modalRef.hide();
    }
  }

  getWriters(page: number = 1, params = {}){
    if (this.authUser.type == 'writer-manager') {
      this.getAllWritersByWriterManager(page, params);
    }else{
      this.getAllWriters(page, params);
    }
  }


  check(e){
    this.selectedWriter = e.target.value;
    console.log('Selected Writer::::::::', this.selectedWriter);
  }

  _initAssociateCounsellorForm() {
		this.associateForm = this.formBuilder.group({
			comments: ['']
		});
	}

  submitWriter(){
    this.submitted = true;
		// stop here if form is invalid
		if (this.associateForm.invalid) {
			return;
		}
    if (this.selectedWriter == '') {
      this.toastr.showWarning('You need to select atleast one.');
      return;
    }

    if (this.authUser.type == 'writer-manager') {
      this.submitWriterByWriterManager();
    }else{
      this.submitWriterByManager();
    }
  }

  submitWriterByManager(){
    this._startLoading();
    let formData = Helper.cloneObj({
      documents: this.document
    }, this.associateForm.value);
    console.log(this.studentId, this.selectedWriter, formData);
    this.userService.associateStudentDocumentWriter(this.studentId, this.selectedWriter, formData).subscribe(
      res => {
        this.toastr.showSuccess('Successfully assigned writer to document');
        this.modalRef.hide();
        this._stopLoading();
      }, 
      error => {
        this._stopLoading();
        console.log(error);
      });
  }

  submitWriterByWriterManager(){
    this._startLoading();
    let formData = Helper.cloneObj({
      documents: this.document
    }, this.associateForm.value);
    console.log(this.studentId, this.selectedWriter, formData);
    this.userService.associateWriterManagerStudentDocumentWriter(this.studentId, this.selectedWriter, formData).subscribe(
      res => {
        this.toastr.showSuccess('Successfully assigned writer to document');
        this.modalRef.hide();
        this._stopLoading();
      }, 
      error => {
        this._stopLoading();
        console.log(error);
      });
  }
  


  _startLoading() {
		this.loading = true;
	}

	_stopLoading() {
		this.loading = false;
	}



}