import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// Layouts
import { FullLayoutComponent } from "./core/components/layouts/full-layout.component";
import { SimpleLayoutComponent } from "./core/components/layouts/simple-layout.component";
import { ManagerAuthGuard } from "./core/guards/manager-auth.guard";
import { CounsellorAuthGuard } from "./core/guards/counsellor-auth.guard";
import { CounsellorManagerAuthGuard } from "./core/guards/counsellor-manager-auth.guard";
import { WriterAuthGuard } from "./core/guards/writer-auth.guard";
import { WriterManagerAuthGuard } from "./core/guards/writer-manager-auth.guard";
import { GuestGuard } from "./core/guards/guest.guard";
import { InterviewTrainerAuthGuard } from "./core/guards/interview-trainer-auth.guard";
import { InterviewTrainerManagerAuthGuard } from "./core/guards/interview-trainer-manager-auth.guard";
import { VisaCounsellorAuthGuard } from "./core/guards/visa-counsellor-auth.guard";
import { VisaCounsellorManagerAuthGuard } from "./core/guards/visa-counsellor-manager-auth.guard";
import { MentorAuthGaurd } from "./core/guards/mentor-auth.guard";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "pages/login",
    pathMatch: "full",
  },
  {
    path: "",
    component: FullLayoutComponent,
    data: {
      title: "Home",
    },
    children: [
      {
        path: "manager",
        loadChildren: "./core/components/manager/manager.module#ManagerModule",
        canActivate: [ManagerAuthGuard],
      },
      {
        path: "mentor",
        loadChildren:"./core/components/mentor/mentor.module#MentorModule",
        canActivate:[MentorAuthGaurd],
      },
      {
        path: "counsellor",
        loadChildren:
          "./core/components/counsellor/counsellor.module#CounsellorModule",
        canActivate: [CounsellorAuthGuard],
      },
      {
        path: "writer",
        loadChildren: "./core/components/writer/writer.module#WriterModule",
        canActivate: [WriterAuthGuard],
      },
      {
        path: "writer-manager",
        loadChildren:
          "./core/components/writer-manager/writer-manager.module#WriterManagerModule",
        canActivate: [WriterManagerAuthGuard],
      },
      {
        path: "counselling-manager",
        loadChildren:
          "./core/components/counselling-manager/counselling-manager.module#CounsellingManagerModule",
        canActivate: [CounsellorManagerAuthGuard],
      },
      {
        path: "interview-trainer",
        loadChildren:
          "./core/components/interview-trainer/interview-trainer.module#InterviewTrainerModule",
        canActivate: [InterviewTrainerAuthGuard],
      },
      {
        path: "interview-trainer-manager",
        loadChildren:
          "./core/components/interview-trainer-manager/interview-trainer-manager.module#InterviewTrainerManagerModule",
        canActivate: [InterviewTrainerManagerAuthGuard],
      },
      {
        path: "visa-counsellor",
        loadChildren:
          "./core/components/visa-counsellor/visa-counsellor.module#VisaCounsellorModule",
        canActivate: [VisaCounsellorAuthGuard],
      },
      {
        path: "visa-counsellor-manager",
        loadChildren:
          "./core/components/visa-counsellor-manager/visa-counsellor-manager.module#VisaCounsellorManagerModule",
        canActivate: [VisaCounsellorManagerAuthGuard],
      },
    ],
  },
  {
    path: "pages",
    component: SimpleLayoutComponent,
    data: {
      title: "Pages",
    },
    canActivate: [GuestGuard],
    children: [
      {
        path: "",
        loadChildren: "./core/components/pages/pages.module#PagesModule",
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
