import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { SharedModule } from "./shared.module";

// Routing Module
import { AppRoutingModule } from "./app.routing";

// Layouts
import { FullLayoutComponent } from "./core/components/layouts/full-layout.component";
import { SimpleLayoutComponent } from "./core/components/layouts/simple-layout.component";

// Modals
import { AppComponent } from "./app.component";
import { UploadModal } from "./core/components/modals/upload/upload.component";
import { SlaReminderModal } from "./core/components/modals/sla-reminder/sla-reminder.component";
import { ScheduleActionModal } from "./core/components/modals/schedule-action/schedule-action.component";
import { UpdateActionModal } from "./core/components/modals/update-action/update-action.component";
import { ChangeCounsellorModal } from "./core/components/modals/change-counsellor/change-counsellor.component";
import { EditFieldsModal } from "./core/components/modals/edit-fields/edit-fields.component";
import { RequestWriterModal } from "./core/components/modals/request-writer/request-writer.component";
import { UpdateTasksModal } from "./core/components/modals/update-tasks/update-tasks.component";
import { UploadDraftModal } from "./core/components/modals/upload-draft/upload-draft.component";
import { SlotSelectionModal } from "./core/components/modals/slot-selection/slot-selection.component";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
// import {CommentAdd} from './core/components/modals/comment-add/comment-add.component'
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { ChangeWriterModal } from "./core/components/modals/change-writer/change-writer.component";
import { ShortlistCollegeModal } from "./core/components/modals/shortlist-college/shortlist-college.component";
 import {CommentAddModal} from './core/components/modals/comment-add/comment-add.component'
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
} from "../../node_modules/@angular/common/http";
import { ManagerAuthGuard } from "./core/guards/manager-auth.guard";
import { CounsellorAuthGuard } from "./core/guards/counsellor-auth.guard";
import { CounsellorManagerAuthGuard } from "./core/guards/counsellor-manager-auth.guard";
import { WriterAuthGuard } from "./core/guards/writer-auth.guard";
import { WriterManagerAuthGuard } from "./core/guards/writer-manager-auth.guard";
import { JwtInterceptorService } from "./core/services/jwt.interceptor.service";
import { ApiRoutes } from "./core/config/api-routes";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AngularFireModule } from "angularfire2";
import { AngularFireDatabaseModule } from "angularfire2/database";
import { environment } from "../environments/environment";

import { ToastrModule } from "ngx-toastr";
import { Toastr } from "./core/plugins/toastr/toastr";
import { AuthenticationService } from "./core/services/authentication.service";
import { GuestGuard } from "./core/guards/guest.guard";
import { UserService } from "./core/services/user.services";
import { AssignCollegeModal } from "./core/components/modals/assign-college/assign-college.component";
import { NgxPaginationModule } from "ngx-pagination";
import { AngularFireAuthModule } from "angularfire2/auth";
import { AngularFireMessagingModule } from "angularfire2/messaging";
import { MessagingService } from "./core/services/messaging.service";
import { FileUploadModule } from "ng2-file-upload";
import { InterviewTrainerAuthGuard } from "./core/guards/interview-trainer-auth.guard";
import { InterviewTrainerManagerAuthGuard } from "./core/guards/interview-trainer-manager-auth.guard";
import { WriteFeedbackModal } from "./core/components/modals/write-feedback/write-feedback.component";
import { ViewFeedbackModal } from "./core/components/modals/view-feedback/view-feedback.component";
import { VisaCounsellorAuthGuard } from "./core/guards/visa-counsellor-auth.guard";
import { VisaCounsellorManagerAuthGuard } from "./core/guards/visa-counsellor-manager-auth.guard";
import { AddRoadmapModal } from "./core/components/modals/add-roadmap/add-roadmap.component";
import { UpdateStudentCollegeResultModal } from "./core/components/modals/update-student-college-result/update-student-college-result.component";
import { BroadcastModal } from "./core/components/modals/broadcast/broadcast.component";
import { CopyDraftModal } from "./core/components/modals/copy-draft/copy-draft.component";

// mentor import

import { MentorAuthGaurd } from "./core/guards/mentor-auth.guard";
import { EditShortlistedCollegeModal } from "./core/components/modals/edit-shortlisted-college/edit-shortlisted-college.component";
import { CKEditorModule } from "ckeditor4-angular";
import { AllCollegeProgram } from "./core/components/modals/all-college-program/all-college-program.component";
import { ReviewCollegeProgram } from "./core/components/modals/review-changes/review-changes.component";
import { UpdateProjectRecommendationModal } from "./core/components/modals/update-project-recommendation/update-project-recommendation.component";

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    SimpleLayoutComponent,
    UploadModal,
    SlaReminderModal,
    ChangeCounsellorModal,
    ChangeWriterModal,
    ScheduleActionModal,
    UpdateActionModal,
    AddRoadmapModal,
    UpdateStudentCollegeResultModal,
    EditFieldsModal,
    RequestWriterModal,
    UpdateTasksModal,
    EditShortlistedCollegeModal,
    UploadDraftModal,
    AssignCollegeModal,
    ViewFeedbackModal,
    WriteFeedbackModal,
    ShortlistCollegeModal,
    BroadcastModal,
    CopyDraftModal,
    SlotSelectionModal,
    CommentAddModal,
    AllCollegeProgram,
    ReviewCollegeProgram,
    UpdateProjectRecommendationModal
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxPaginationModule,
    FileUploadModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      maxOpened: 1,
      autoDismiss: true,
    }), // ToastrModule added
    SharedModule,
    BrowserModule,
    AngularFireAuthModule,
    CKEditorModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule, // for database
  ],
  entryComponents: [
    UploadModal,
    SlaReminderModal,
    UpdateStudentCollegeResultModal,
    ChangeCounsellorModal,
    ChangeWriterModal,
    ScheduleActionModal,
    UpdateActionModal,
    AddRoadmapModal,
    EditFieldsModal,
    EditShortlistedCollegeModal,
    UpdateProjectRecommendationModal,
    RequestWriterModal,
    ViewFeedbackModal,
    WriteFeedbackModal,
    AssignCollegeModal,
    UpdateStudentCollegeResultModal,
    UpdateTasksModal,
    UploadDraftModal,
    ShortlistCollegeModal,
    BroadcastModal,
    CopyDraftModal,
    SlotSelectionModal,
    CommentAddModal,
    AllCollegeProgram,
    ReviewCollegeProgram
  ],
  bootstrap: [AppComponent],
  providers: [
    AuthenticationService,
    Toastr,
    ApiRoutes,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    ManagerAuthGuard,
    CounsellorAuthGuard,
    MessagingService,
    CounsellorManagerAuthGuard,
    InterviewTrainerAuthGuard,
    VisaCounsellorAuthGuard,
    VisaCounsellorManagerAuthGuard,
    InterviewTrainerManagerAuthGuard,
    WriterAuthGuard,
    WriterManagerAuthGuard,
    MentorAuthGaurd,
    GuestGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorService,
      multi: true,
    },
    UserService,
  ],
})
export class AppModule {}
