import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiRoutes } from "../config/api-routes";
import "rxjs/Rx";
import { Router } from "@angular/router";
import { Storage } from "../utilities/storage";
import { Helper } from "../utilities/helpers";
import { AuthenticationService } from "./authentication.service";

@Injectable()
export class CollegeService {
  public currentUser: any;
  public currentOwner: any;

  constructor(
    private http: HttpClient,
    private apiRoutes: ApiRoutes,
    private router: Router,
    public authService: AuthenticationService,
    public storage: Storage
  ) {
    this.currentUser = this.authService.authUser;
    this.currentOwner = Helper.getObjProp(this.currentUser, "owner");
  }

  getStudentAllShortlistedColleges(params = {}, studentId) {
    let url =
      this.apiRoutes.getstudentAllShortlistedCollegesUrl(
        studentId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  updateChecklistCounsellor(studentId, collegeId, checklist) {
    return this.http
      .put(
        this.apiRoutes.getUpdateCounsellorStudentCollege(
          this.currentUser.id,
          studentId,
          collegeId
        ),
        checklist
      )
      .map((res: any) => {
        return res;
      });
  }

  updateChecklistMentor(studentId, collegeId, checklist) {
    return this.http
      .put(
        this.apiRoutes.getUpdateMentorStudentCollege(
          this.currentUser.id,
          studentId,
          collegeId
        ),
        checklist
      )
      .map((res: any) => {
        return res;
      });
  }

  getWriterManagerStudentAllShortlistedColleges(params = {}, studentId) {
    let url =
      this.apiRoutes.getWriterManagerStudentAllShortlistedCollegesUrl(
        studentId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }
  getWriterStudentAllShortlistedColleges(params = {}, studentId) {
    let url =
      this.apiRoutes.getWriterStudentAllShortlistedCollegesUrl(
        studentId,
        this.currentUser.owner.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getCounsellorStudentAllShortlistedColleges(params = {}, studentId) {
    let url =
      this.apiRoutes.getCounsellorStudentAllShortlistedCollegesUrl(
        studentId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getMentorsStudentAllShortlistedColleges(params = {}, studentId) {
    let url =
      this.apiRoutes.getMentorStudentAllShortlistedCollegesUrl(
        studentId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res) => {
      return res;
    });
  }
  getMentorStudentAllQualityCheckColleges(params = {}, studentId) {
    let url =
      this.apiRoutes.getMentorAllQualityCheckCollegesUrl(
        studentId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }
  getVisaCounsellorStudentAllShortlistedColleges(params = {}, studentId) {
    let url =
      this.apiRoutes.getVisaCounsellorStudentAllShortlistedCollegesUrl(
        studentId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getInterviewTrainerStudentAllShortlistedColleges(params = {}, studentId) {
    let url =
      this.apiRoutes.getInterviewTrainerStudentAllShortlistedCollegesUrl(
        studentId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getCounsellingManagerStudentAllShortlistedColleges(params = {}, studentId) {
    let url =
      this.apiRoutes.getCounsellingManagerStudentAllShortlistedCollegesUrl(
        studentId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }
  getCounsellingManagerStudentAllQualityCheckColleges(params = {}, studentId) {
    let url =
      this.apiRoutes.getCounsellingManagerStudentAllQualityCheckCollegesUrl(
        studentId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }
  getCounsellorStudentAllQualityCheckColleges(params = {}, studentId) {
    let url =
      this.apiRoutes.getCounsellorAllQualityCheckCollegesUrl(
        studentId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getVisaCounsellingManagerStudentAllShortlistedColleges(
    params = {},
    studentId
  ) {
    let url =
      this.apiRoutes.getVisaCounsellingManagerStudentAllShortlistedCollegesUrl(
        studentId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getInterviewTrainerManagerStudentAllShortlistedColleges(
    params = {},
    studentId
  ) {
    let url =
      this.apiRoutes.getInterviewTrainerManagerStudentAllShortlistedCollegesUrl(
        studentId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getStudentProgramDetails(studentId, programId, params = {}) {
    let url =
      this.apiRoutes.getstudentProgramDetailsUrl(
        studentId,
        programId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getStudentSelfShortlistedCollegeProgramDetails(
    studentId,
    programId,
    params = {}
  ) {
    let url =
      this.apiRoutes.getstudentProgramDetailsUrl(
        studentId,
        programId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getWriterManagerStudentProgramDetails(studentId, programId, params = {}) {
    let url =
      this.apiRoutes.getWriterManagerStudentProgramDetailsUrl(
        studentId,
        programId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }
  getWriterStudentProgramDetails(studentId, programId, params = {}) {
    let url =
      this.apiRoutes.getWriterStudentProgramDetailsUrl(
        studentId,
        programId,
        this.currentUser.owner.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getCounsellorStudentProgramDetails(studentId, programId, params = {}) {
    let url =
      this.apiRoutes.getCounsellorStudentProgramDetailsUrl(
        studentId,
        programId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getMentorStudentProgramDetails(studentId, programId, params = {}) {
    let url =
      this.apiRoutes.getMentorStudentProgramDetailsUrl(
        studentId,
        programId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getVisaCounsellorStudentProgramDetails(studentId, programId, params = {}) {
    let url =
      this.apiRoutes.getVisaCounsellorStudentProgramDetailsUrl(
        studentId,
        programId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getInterviewTrainerStudentProgramDetails(studentId, programId, params = {}) {
    let url =
      this.apiRoutes.getInterviewTrainerStudentProgramDetailsUrl(
        studentId,
        programId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getInterviewTrainerStudentInterviewSessions(
    studentId,
    interviewId,
    params = {}
  ) {
    let url =
      this.apiRoutes.getInterviewTrainerStudentInterviewSessionsUrl(
        studentId,
        interviewId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  createInterviewTrainerStudentInterviewSessionFeedback(
    studentId,
    interviewId,
    interviewSessionId,
    formData,
    params
  ) {
    let url =
      this.apiRoutes.getInterviewTrainerStudentInterviewSessionsFeedbackUrl(
        studentId,
        interviewId,
        interviewSessionId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.post(url, formData).map((res: any) => {
      return res;
    });
  }

  createInterviewTrainerStudentInterviewSession(
    studentId,
    interviewId,
    formData,
    params
  ) {
    let url =
      this.apiRoutes.getCreateInterviewTrainerStudentInterviewSessionsUrl(
        studentId,
        interviewId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.post(url, formData).map((res: any) => {
      return res;
    });
  }

  createVisaCounsellorStudentVisaSession(
    studentId,
    interviewId,
    formData,
    params
  ) {
    let url =
      this.apiRoutes.createVisaCounsellorStudentVisaSessionUrl(
        studentId,
        interviewId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.post(url, formData).map((res: any) => {
      return res;
    });
  }

  getCounsellingManagerStudentProgramDetails(
    studentId,
    programId,
    params = {}
  ) {
    let url =
      this.apiRoutes.getCounsellingManagerStudentProgramDetailsUrl(
        studentId,
        programId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }
  // get counselling manager
  getCounsellStudentProgramDetails(studentId, programId, params = {}) {
    let url =
      this.apiRoutes.getCounsellingStudentProgramDetailsUrl(
        studentId,
        programId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getVisaCounsellingManagerStudentProgramDetails(
    studentId,
    programId,
    params = {}
  ) {
    let url =
      this.apiRoutes.getVisaCounsellingManagerStudentProgramDetailsUrl(
        studentId,
        programId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getInterviewTrainerManagerStudentProgramDetails(
    studentId,
    programId,
    params = {}
  ) {
    let url =
      this.apiRoutes.getInterviewTrainerManagerStudentProgramDetailsUrl(
        studentId,
        programId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getVisaCounsellorManagerStudentProgramDetails(
    studentId,
    programId,
    params = {}
  ) {
    let url =
      this.apiRoutes.getVisaCounsellorManagerStudentProgramDetailsUrl(
        studentId,
        programId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getStudentSelfShortlistedColleges(params = {}, studentId) {
    let url =
      this.apiRoutes.getstudentAllSelfShortlistedCollegesUrl(
        studentId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getWriterManagerStudentSelfShortlistedColleges(params = {}, studentId) {
    let url =
      this.apiRoutes.getWriterManagerStudentAllSelfShortlistedCollegesUrl(
        studentId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }
  getWriterStudentSelfShortlistedColleges(params = {}, studentId) {
    let url =
      this.apiRoutes.getWriterStudentAllSelfShortlistedCollegesUrl(
        studentId,
        this.currentUser.owner.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getCounsellingManagerStudentSelfShortlistedColleges(params = {}, studentId) {
    let url =
      this.apiRoutes.getstudentCounsellingManagerAllSelfShortlistedCollegesUrl(
        studentId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getVisaCounsellingManagerStudentSelfShortlistedColleges(
    params = {},
    studentId
  ) {
    let url =
      this.apiRoutes.getstudentVisaCounsellingManagerAllSelfShortlistedCollegesUrl(
        studentId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getInterviewTrainerManagerStudentSelfShortlistedColleges(
    params = {},
    studentId
  ) {
    let url =
      this.apiRoutes.getstudentInterviewTrainerManagerAllSelfShortlistedCollegesUrl(
        studentId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getCounsellorStudentSelfShortlistedColleges(params = {}, studentId) {
    let url =
      this.apiRoutes.getCounsellorStudentAllSelfShortlistedCollegesUrl(
        studentId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getMentorStudentSelfShortlistedColleges(params = {}, studentId) {
    let url =
      this.apiRoutes.getMentorStudentAllSelfShortlistedCollegesUrl(
        studentId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getVisaCounsellorStudentSelfShortlistedColleges(params = {}, studentId) {
    let url =
      this.apiRoutes.getVisaCounsellorStudentAllSelfShortlistedCollegesUrl(
        studentId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getInterviewTrainerStudentSelfShortlistedColleges(params = {}, studentId) {
    let url =
      this.apiRoutes.getInterviewTrainerStudentAllSelfShortlistedCollegesUrl(
        studentId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getStudentProgramDocuments(studentId, programId) {
    return this.http
      .get(
        this.apiRoutes.getstudentProgramDocumentsUrl(
          studentId,
          programId,
          this.currentUser.id
        )
      )
      .map((res: any) => {
        return res;
      });
  }

  getWriterManagerStudentProgramDocuments(studentId, programId) {
    return this.http
      .get(
        this.apiRoutes.getWriterManagerStudentProgramDocumentsUrl(
          studentId,
          programId,
          this.currentUser.id
        )
      )
      .map((res: any) => {
        return res;
      });
  }

  getCounsellorStudentProgramDocuments(studentId, programId) {
    return this.http
      .get(
        this.apiRoutes.getCounsellorStudentProgramDocumentsUrl(
          studentId,
          programId,
          this.currentUser.id
        )
      )
      .map((res: any) => {
        return res;
      });
  }

  getMentorStudentProgramDocuments(studentId, programId) {
    return this.http
      .get(
        this.apiRoutes.getMentorStudentProgramDocumentsUrl(
          studentId,
          programId,
          this.currentUser.id
        )
      )
      .map((res: any) => {
        return res;
      });
  }

  getInterviewTrainerStudentProgramDocuments(studentId, programId) {
    return this.http
      .get(
        this.apiRoutes.getInterviewTrainerStudentProgramDocumentsUrl(
          studentId,
          programId,
          this.currentUser.id
        )
      )
      .map((res: any) => {
        return res;
      });
  }

  getCounsellingManagerStudentProgramDocuments(studentId, programId) {
    return this.http
      .get(
        this.apiRoutes.getCounsellingManagerStudentProgramDocumentsUrl(
          studentId,
          programId,
          this.currentUser.id
        )
      )
      .map((res: any) => {
        return res;
      });
  }

  getVisaCounsellingManagerStudentProgramDocuments(studentId, programId) {
    return this.http
      .get(
        this.apiRoutes.getVisaCounsellingManagerStudentProgramDocumentsUrl(
          studentId,
          programId,
          this.currentUser.id
        )
      )
      .map((res: any) => {
        return res;
      });
  }

  getInterviewTrainerManagerStudentProgramDocuments(studentId, programId) {
    return this.http
      .get(
        this.apiRoutes.getInterviewTrainerManagerStudentProgramDocumentsUrl(
          studentId,
          programId,
          this.currentUser.id
        )
      )
      .map((res: any) => {
        return res;
      });
  }

  getStudentProgramDocumentDetails(studentId, programId, documentId) {
    return this.http
      .get(
        this.apiRoutes.getStudentProgramDocumentDetailsUrl(
          studentId,
          programId,
          this.currentUser.id,
          documentId
        )
      )
      .map((res: any) => {
        return res;
      });
  }
  getWriterStudentProgramDocumentDetails(studentId, programId, documentId) {
    return this.http
      .get(
        this.apiRoutes.getWriterStudentProgramDocumentDetailsUrl(
          studentId,
          programId,
          this.currentUser.id,
          documentId
        )
      )
      .map((res: any) => {
        return res;
      });
  }

  getWriterManagerStudentProgramDocumentDetails(
    studentId,
    programId,
    documentId
  ) {
    return this.http
      .get(
        this.apiRoutes.getWriterManagerStudentProgramDocumentDetailsUrl(
          studentId,
          programId,
          this.currentUser.id,
          documentId
        )
      )
      .map((res: any) => {
        return res;
      });
  }

  getCounsellorStudentProgramDocumentDetails(studentId, programId, documentId) {
    return this.http
      .get(
        this.apiRoutes.getCounsellorStudentProgramDocumentDetailsUrl(
          studentId,
          programId,
          this.currentUser.id,
          documentId
        )
      )
      .map((res: any) => {
        return res;
      });
  }

  getMentorStudentProgramDocumentDetails(studentId, programId, documentId) {
    return this.http
      .get(
        this.apiRoutes.getMentorStudentProgramDocumentDetailsUrl(
          studentId,
          programId,
          this.currentUser.id,
          documentId
        )
      )
      .map((res: any) => {
        return res;
      });
  }

  getInterviewTrainerStudentProgramDocumentDetails(
    studentId,
    programId,
    documentId
  ) {
    return this.http
      .get(
        this.apiRoutes.getInterviewTrainerStudentProgramDocumentDetailsUrl(
          studentId,
          programId,
          this.currentUser.id,
          documentId
        )
      )
      .map((res: any) => {
        return res;
      });
  }

  getCounsellingManagerStudentProgramDocumentDetails(
    studentId,
    programId,
    documentId
  ) {
    return this.http
      .get(
        this.apiRoutes.getCounsellingManagerStudentProgramDocumentDetailsUrl(
          studentId,
          programId,
          this.currentUser.id,
          documentId
        )
      )
      .map((res: any) => {
        return res;
      });
  }

  getVisaCounsellingManagerStudentProgramDocumentDetails(
    studentId,
    programId,
    documentId
  ) {
    return this.http
      .get(
        this.apiRoutes.getVisaCounsellingManagerStudentProgramDocumentDetailsUrl(
          studentId,
          programId,
          this.currentUser.id,
          documentId
        )
      )
      .map((res: any) => {
        return res;
      });
  }

  getInterviewTrainerManagerStudentProgramDocumentDetails(
    studentId,
    programId,
    documentId
  ) {
    return this.http
      .get(
        this.apiRoutes.getInterviewTrainerManagerStudentProgramDocumentDetailsUrl(
          studentId,
          programId,
          this.currentUser.id,
          documentId
        )
      )
      .map((res: any) => {
        return res;
      });
  }

  getStudentProgramDocumentDrafts(
    studentId,
    programId,
    documentId,
    params = {}
  ) {
    let url =
      this.apiRoutes.getStudentProgramDocumentDraftsUrl(
        studentId,
        programId,
        this.currentUser.id,
        documentId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getWriterStudentProgramDocumentDrafts(
    studentId,
    programId,
    documentId,
    params = {}
  ) {
    let url =
      this.apiRoutes.getWriterStudentProgramDocumentDraftsUrl(
        studentId,
        programId,
        this.currentUser.id,
        documentId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getWriterManagerStudentProgramDocumentDrafts(
    studentId,
    programId,
    documentId,
    params = {}
  ) {
    let url =
      this.apiRoutes.getWriterManagerStudentProgramDocumentDraftsUrl(
        studentId,
        programId,
        this.currentUser.id,
        documentId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getCounsellorStudentProgramDocumentDrafts(
    studentId,
    programId,
    documentId,
    params = {}
  ) {
    let url =
      this.apiRoutes.getCounsellorStudentProgramDocumentDraftsUrl(
        studentId,
        programId,
        this.currentUser.id,
        documentId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }
  getMentorStudentProgramDocumentDrafts(
    studentId,
    programId,
    documentId,
    params = {}
  ) {
    let url =
      this.apiRoutes.getMentorStudentProgramDocumentDraftsUrl(
        studentId,
        programId,
        this.currentUser.id,
        documentId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getInterviewTrainerStudentProgramDocumentDrafts(
    studentId,
    programId,
    documentId,
    params = {}
  ) {
    let url =
      this.apiRoutes.getInterviewTrainerStudentProgramDocumentDraftsUrl(
        studentId,
        programId,
        this.currentUser.id,
        documentId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getCounsellingManagerStudentProgramDocumentDrafts(
    studentId,
    programId,
    documentId,
    params = {}
  ) {
    let url =
      this.apiRoutes.getCounsellingManagerStudentProgramDocumentDraftsUrl(
        studentId,
        programId,
        this.currentUser.id,
        documentId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getVisaCounsellingManagerStudentProgramDocumentDrafts(
    studentId,
    programId,
    documentId,
    params = {}
  ) {
    let url =
      this.apiRoutes.getVisaCounsellingManagerStudentProgramDocumentDraftsUrl(
        studentId,
        programId,
        this.currentUser.id,
        documentId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getInterviewTrainerManagerStudentProgramDocumentDrafts(
    studentId,
    programId,
    documentId,
    params = {}
  ) {
    let url =
      this.apiRoutes.getInterviewTrainerManagerStudentProgramDocumentDraftsUrl(
        studentId,
        programId,
        this.currentUser.id,
        documentId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getCollegeStudents(collegeId, params = {}) {
    let url =
      this.apiRoutes.getCollegeStudentsUrl(this.currentUser.id, collegeId) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getProgram(collegeId) {
    return this.http
      .get(this.apiRoutes.getProgramUrl(this.currentUser.id, collegeId))
      .map((res: any) => {
        return res;
      });
  }

  getCounsellingManagerCounsellorProgram(collegeId) {
    return this.http
      .get(
        this.apiRoutes.getCounsellingManagerCounsellorProgramUrl(
          this.currentUser.id,
          collegeId
        )
      )
      .map((res: any) => {
        return res;
      });
  }

  getCounsellorCollegeProgram(collegeId) {
    return this.http
      .get(
        this.apiRoutes.getCounsellorProgramUrl(
          this.currentUser.id,
          collegeId
        )
      )
      .map((res: any) => {
        return res;
      });
  }

  addProgram(form) {
    return this.http
      .post(this.apiRoutes.getAddProgamUrl(), form)
      .map((res: any) => {
        return res;
      });
  }

  updateProgram(collegeId, data) {
    return this.http
      .put(this.apiRoutes.getProgramUrl(this.currentUser.id, collegeId), data)
      .map((res: any) => {
        return res;
      });
  }

  getProgramByCategory(categoryId) {
    return this.http
      .get(this.apiRoutes.getProgramByCategoryUrl(categoryId))
      .map((res: any) => {
        return res;
      });
  }

  getProgramCategories() {
    return this.http
      .get(this.apiRoutes.getProgramCategoriesUrl())
      .map((res: any) => {
        return res;
      });
  }

  getAllColleges(params = {}) {
    let url =
      this.apiRoutes.getCollegesUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getAllLocations(params = {}) {
    let url =
      this.apiRoutes.getLocationsUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getCounsellorAllLocations(params = {}) {
    let url =
      this.apiRoutes.getCounsellorLocationsUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getCounsellorManagerLocations(params = {}) {
    let url =   this.apiRoutes.getCounsellorManagerLocationsUrl(this.currentUser.id) +
    Helper.getQueryParamsString(params);
  return this.http.get(url).map((res: any) => {
    return res;
  });
}

  getAllCoursetypes(params = {}) {
    let url =
      this.apiRoutes.getCourseTypesUrl() + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getCollegeById(collegeId) {
    let url = this.apiRoutes.getCollegeByIdUrl(collegeId, this.currentUser.id);
    return this.http.get(url).map((res: any) => {
      return res.data;
    });
  }

  getLocationById(locationId) {
    let url = this.apiRoutes.getLocationByIdUrl(
      locationId,
      this.currentUser.id
    );
    return this.http.get(url).map((res: any) => {
      return res.data;
    });
  }

  addCollege(form) {
    let url = this.apiRoutes.getAddCollegeUrl(this.currentUser.id);
    return this.http.post(url, form).map((res: any) => {
      return res;
    });
  }

  addLocation(form) {
    let url = this.apiRoutes.getAddLocationUrl(this.currentUser.id);
    return this.http.post(url, form).map((res: any) => {
      return res;
    });
  }

  updateCollegeById(collegeId, form) {
    let url = this.apiRoutes.getCollegeByIdUrl(collegeId, this.currentUser.id);
    return this.http.put(url, form).map((res: any) => {
      return res;
    });
  }

  updateLocationById(locationId, form) {
    let url = this.apiRoutes.getLocationByIdUrl(
      locationId,
      this.currentUser.id
    );
    return this.http.put(url, form).map((res: any) => {
      return res;
    });
  }
  updateStudentProgramDetailsByCounsellingManager(studentId, programId, form) {
    let url = this.apiRoutes.getShortlistedCollegeProgramByCounsellingManager(
      this.currentUser.id,
      studentId,
      programId
    );
    return this.http.put(url, form).map((res: any) => {
      return res;
    });
  }
  //getShortlistedCollegeProgramByWriter
  updateStudentProgramDetailsWriter(
    studentId,
    programId,
    documentId,
    draftId,
    form
  ) {
    let url = this.apiRoutes.getShortlistedCollegeProgramByWriter(
      this.currentUser.id,
      studentId,
      programId,
      documentId,
      draftId
    );
    return this.http.put(url, form).map((res: any) => {
      return res;
    });
  }

  deleteStudentProgramDetailsByCounsellingManager(studentId, programId) {
    let url = this.apiRoutes.getShortlistedCollegeProgramByCounsellingManager(
      this.currentUser.id,
      studentId,
      programId
    );
    return this.http.delete(url).map((res: any) => {
      return res;
    });
  }
  // updateStudent Program Details By Counselling Manager
  updateStudentProgramDetailsByCounsellor(studentId, programId, form) {
    let url = this.apiRoutes.getShortlistedCollegeProgramByCounsellor(
      this.currentUser.id,
      studentId,
      programId
    );
    return this.http.put(url, form).map((res: any) => {
      return res;
    });
  }

  udpateShortlistedCollegeStep(studentId, programId, form) {
    let url = this.apiRoutes.getShortlistedCollegeStepUpdateUrl(
      this.currentUser.id,
      studentId,
      programId
    );
    return this.http.put(url, form).map((res: any) => {
      return res;
    });
  }
}
