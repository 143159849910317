import { Component, TemplateRef, OnInit } from "@angular/core";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Toastr } from "../../../plugins/toastr/toastr";
import { Validators, FormGroup, FormBuilder } from "@angular/forms";
import { CustomValidator } from "../../../utilities/validator/custom-validator";
import * as moment from "moment";
import { StudentService } from "src/app/core/services/student.service";
import { Helper } from "../../../utilities/helpers";
import { Storage } from "../../../utilities/storage";
import { ModalOptions } from "ngx-bootstrap";


import { Observable } from "rxjs";
import { CollegeProgramService } from "src/app/core/services/college.program.service";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/core/services/authentication.service";

@Component({
  selector: "all-college-program-modal",
  templateUrl: "./all-college-program.component.html",
  providers: [StudentService, Toastr, CollegeProgramService],
})
export class AllCollegeProgram implements OnInit {
  colleges: any = [];
  public loading: boolean = false;
  public paginationData: any;
  public total: number;
  public page: number = 0;
  public per_page: number = 0;
  public collegeProgramInitialised: boolean = false;
  public authUser: any;
  public searchInputValue: any = '';
  public sortByParams: Object = {
    college_name: false,
    program_name: false,
    f1_deadline: false,
    alliance: false
  };
  public sort_by: any = {
    column: '',
    type: ''
  };

  constructor(
    public collegeProgramsService: CollegeProgramService,
    public modalRef: BsModalRef,
    public router: Router,
    public authService: AuthenticationService,
  ) {}

  ngOnInit() {
    this._startLoading();
    this.getAllColleges();
  }

  _onSortByClick(column_name: string) {
    this._toggleSortByParams(column_name);
    this.getAllColleges();
  }

  _toggleSortByParams(key: string = '') {
    for (var property in this.sortByParams) {
      if (this.sortByParams.hasOwnProperty(property)) {
        if (property == key) {
          this.sortByParams[key] = (this.sortByParams[key] == false) ? 'desc' : (this.sortByParams[key] == 'desc') ? 'asc' : 'desc';
          this.sort_by = {
            column: key,
            type: this.sortByParams[key]
          };
        } else {
          this.sortByParams[property] = false;
        }
      }
    }
  }
  closeModal(modalRef: any) {
    modalRef.hide();
  }

  getAllColleges(page = 1, params: Object = {}) {
    this._startLoading();
    let sort_by = '';
    for (let property in this.sortByParams) {
      if (this.sortByParams[property]) {
        sort_by = property + ',' + this.sortByParams[property];
        break;
      }
    }
    params = Helper.cloneObj({
      query: this.searchInputValue,
      sort_by: sort_by,
      page: page,
    }, params);
    this.colleges = [];
    this.collegeProgramsService.getAllCollegeProgram(params).subscribe(
      res => {
        let index = 0;
        this.paginationData = res.meta.pagination;
        this.total = this.paginationData.total;
        this.per_page = this.paginationData.per_page;
        this.page = this.paginationData.current_page;
        res.data.forEach(element => {
          index = index + 1;
          let alliance = this.getAllianceStatus(element);
          let status = this.getStatus(element);
          let tempObject = {
            id: index,
            programId: element.meta_data.slug,
            collegeProgramName: element.meta_data.name,
            collegeName: element.college.name,
            programName: element.program.name,
            programCategoryName: element.program.program_category.name,
            deadline: (element.nearest_deadline_formatted) ? element.nearest_deadline_formatted : '--',
            alliance: alliance.status,
            allianceClass: alliance.class,
            status: status.status,
            statusClass: status.statusClass
          };

          this.colleges.push(tempObject);
        });
        this._stopLoading();
		// this.modalRef.hide()
      },
      error => {
        this._stopLoading();
        console.log(error);
      }
    );
  }


  goToCollege(collegeProgamSlug) {
      this.router.navigate([
        "counsellor/colleges/individual-college/" +
          collegeProgamSlug +
          "/college-details",
      ]);
      this.modalRef.hide();
  }

  getAllianceStatus(element) {
    let tempObject = {
      status: '',
      class: '',
    };
    switch (element.meta_data.alliance) {
      case false:
        tempObject = {
          status: '--',
          class: 'disabled'
        };
        break;

      case true:
        tempObject = {
          status: 'Alliance',
          class: 'info'
        };
        break;

      default:
        tempObject = {
          status: 'Alliance',
          class: 'info'
        };
        break;
    }

    return tempObject;
  }

  getStatus(element) {
    let tempObject = {
      status: '',
      statusClass: '',
    };
    switch (element.is_alliance) {
      case 0:
        tempObject = {
          status: element.status_text,
          statusClass: 'disabled'
        };
        break;

      case 1:
        tempObject = {
          status: element.status_text,
          statusClass: 'success'
        };
        break;

      default:
        tempObject = {
          status: element.status_text,
          statusClass: 'success'
        };
        break;
    }

    return tempObject;
  }

  _startLoading() {
    this.loading = true;
  }

  _stopLoading() {
    this.loading = false;
  }
}
