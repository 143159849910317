import { Component, TemplateRef, OnInit } from "@angular/core";

import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { CustomValidator } from "src/app/core/utilities/validator/custom-validator";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Helper } from "src/app/core/utilities/helpers";
import { StudentService } from "src/app/core/services/student.service";
import { Toastr } from "src/app/core/plugins/toastr/toastr";
import { AuthenticationService } from "src/app/core/services/authentication.service";

@Component({
  selector: "update-action-modal",
  templateUrl: "./update-action.component.html",
  providers: [StudentService],
})
export class UpdateActionModal implements OnInit {
  actionName: any;
  studentId: any;
  actionId: any;
  message: string = "";
  public commentsForm: FormGroup;
  public loading: boolean = false;
  public authUser: any;
  // public message: string = "";

  constructor(
    public modalRef: BsModalRef,
    private formBuilder: FormBuilder,
    public studentService: StudentService,
    public toastr: Toastr,
    public authService: AuthenticationService
  ) {}

  ngOnInit() {
    this._fetchAuthUser();
    this._initForm();
  }

  _fetchAuthUser() {
    this.authUser = this.authService.authUser;
  }

  _initForm() {
    this.commentsForm = this.formBuilder.group({
      comments: ["", CustomValidator.lessThenLength(254)],
    });
  }

  submit() {
    console.log("============<>", this.message);
    if (this.message.length > 65535) {
      return;
    }
    // if (this.commentsForm.invalid) {
    //   return;
    // }

    if (this.authUser.is_counsellor) {
      this.submitByCounsellor();
    } else if (this.authUser.is_mentor) {
      this.submitByMentor();
    } else {
      this.submitByWriter();
    }
  }

  submitByCounsellor() {
    this._startLoading();
    let tempObject = Helper.cloneObj(
      {
        is_completed: true,
      },
      { comments: this.message }
      // this.commentsForm.value
    );
    console.log(
      "🚀 ~ file: update-action.component.ts:76 ~ UpdateActionModal ~ submitByCounsellor ~ tempObject",
      tempObject
    );
    this.studentService
      .updateCounsellorStudentHistory(this.studentId, this.actionId, tempObject)
      .subscribe(
        (res) => {
          this.toastr.showSuccess("Successfully updated student history.");
          this.modalRef.hide();
          this._stopLoading();
        },
        (error) => {
          this._stopLoading();
        }
      );
  }

  submitByWriter() {
    this._startLoading();
    let tempObject = Helper.cloneObj(
      {
        is_completed: true,
      },
      { comments: this.message }

      // this.commentsForm.value
    );
    this.studentService
      .updateWriterStudentHistory(this.studentId, this.actionId, tempObject)
      .subscribe(
        (res) => {
          this.toastr.showSuccess("Successfully updated student history.");
          this.modalRef.hide();
          this._stopLoading();
        },
        (error) => {
          this._stopLoading();
        }
      );
  }

  submitByMentor() {
    this._startLoading();
    let tempObject = Helper.cloneObj(
      {
        is_completed: true,
      },
      // this.commentsForm.value
      { comments: this.message }
    );
    // console.log(
    //   "🚀 ~ file: update-action.component.ts:128 ~ UpdateActionModal ~ submitByMentor ~ tempObject",
    //   tempObject
    // );
    this.studentService
      .updateMentorStudentHistory(this.studentId, this.actionId, tempObject)
      .subscribe(
        (res) => {
          this.toastr.showSuccess("Sucessfully updated student history");
          this.modalRef.hide();
          this._stopLoading();
        },
        (error) => {
          this._stopLoading();
        }
      );
  }

  _startLoading() {
    this.loading = true;
  }

  _stopLoading() {
    this.loading = false;
  }
}
