import {Component, TemplateRef} from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
  selector: 'sla-reminder-modal',
  templateUrl: './sla-reminder.component.html'
})
export class SlaReminderModal {

  constructor(public modalRef: BsModalRef) {}

}