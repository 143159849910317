import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
// import { Router } from '@angular/router';
import * as moment from "moment";
import { UserService } from "../../services/user.services";
import { Helper } from "../../utilities/helpers";
import { element } from "protractor";
import { AuthenticationService } from "../../services/authentication.service";
import { Router } from "@angular/router";
import { AngularFireDatabase } from "angularfire2/database";
import settings from "../../config/settings";
// import { ContactModal } from '../modals/contact/contact.component';
import { Toastr } from "src/app/core/plugins/toastr/toastr";

@Component({
  selector: "app-dashboard",
  templateUrl: "./full-layout.component.html",
  providers: [UserService, AuthenticationService],
})
export class FullLayoutComponent implements OnInit {
  public authUser: any;
  public notifications: any = [];
  public unReadNotifications: any = [];
  public unReadNotificationClass: any = {
    box: "pulse-glow-repeat-0-2",
    text: "",
  };
  public MAX_NOTIFICATION_LIMIT: number = 99;
  public date: any = new Date();
  public year: any;
  public ASSIGN_WRITER_NOTIFICATION_TYPE: number = 41;

  constructor(
    public userService: UserService,
    public authService: AuthenticationService,
    public router: Router,
    public db: AngularFireDatabase,
    public toastr: Toastr
  ) {
    this.year = moment(this.date).format("YYYY");
  }

  status: boolean = true;
  displaySidebar() {
    this.status = !this.status;
  }

  ngOnInit(): void {
    this.getCurrentUser();
  }

  _fetchUnreadNotificationCount() {
    this.db
      .object(`/users/${this.authUser.id}`)
      .valueChanges()
      .subscribe((res: any) => {
        console.log("class emptied!");
        this.unReadNotificationClass.text = "";
        if (res) {
          this.unReadNotifications.length = res.unread_notifications_count;
        }
        this.getNotifications();
        setTimeout(() => {
          console.log("class changed!");
          if (Helper.isEmpty(this.unReadNotificationClass.box)) {
            this.unReadNotificationClass.box = "pulse-glow-repeat-0-2";
          } else {
            this.unReadNotificationClass.text = "animated flash";
          }
        }, 100);
      });
  }

  _onNotificationClicked(event: any) {
    this.unReadNotificationClass.box = "";
  }

  getCurrentUser() {
    this.authUser = this.authService.authUser;
    this._fetchUnreadNotificationCount();
    this.getNotifications();
  }

  navigateToChat() {
    if (this.authUser.is_admin) {
      this.router.navigate(["/manager/chat"]);
    } else if (this.authUser.is_counsellor) {
      this.router.navigate(["/counsellor/chat"]);
    } else if (this.authUser.is_counsellor_manager) {
      this.router.navigate(["/counselling-manager/chat"]);
    } else if (this.authUser.is_writer) {
      this.router.navigate(["/writer/chat"]);
    } else if (this.authUser.is_writer_manager) {
      this.router.navigate(["/writer-manager/chat"]);
    } else if (this.authUser.is_interview_manager) {
      this.router.navigate(["/interview-trainer-manager/chat"]);
    } else if (this.authUser.is_interview_trainer) {
      this.router.navigate(["/interview-trainer/chat"]);
    } else if (this.authUser.is_visa_counsellor_manager) {
      this.router.navigate(["/visa-counsellor-manager/chat"]);
    } else if (this.authUser.is_visa_counsellor) {
      this.router.navigate(["/visa-counsellor/chat"]);
    } else if (this.authUser.is_mentor) {
      this.router.navigate(["/mentor/chat"]);
    }
  }

  getNotifications(params = {}) {
    params = Helper.cloneObj(
      {
        limit: 100,
      },
      params
    );
    this.userService.getNotifications(params).subscribe(
      (res) => {
        this.notifications = [];
        let message;
        res.data.forEach((element) => {
          // if (element.type === this.ASSIGN_WRITER_NOTIFICATION_TYPE && !element.is_read ){
          // this.toastr.showSuccess(element.meaningful_details.message,element.meaningful_details.title)
          //}
          let url = this.getUrl(element);
          let time = moment(element.created_at).fromNow();
          message = element.meaningful_details.message;
          let tempObject = {
            id: element.id,
            url: url,
            is_read: element.is_read,
            name: element.meaningful_details.title,
            message: message,
            time: time,
            type: element.type,
            content: message,
            meta_data: element.metadata,
          };
          this.notifications.push(tempObject);
        });

        this.unReadNotifications = res.data.filter(this.checkRead);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  goToParticularNotification(element) {
    console.log("Here is the element ", element);
    if (!element.is_read) {
      let notificationId = {
        notifications_read: [element.id],
      };
      this.userService.readNotifications(notificationId).subscribe();
    }
    let dbNotificationTypes = settings.databaseNotifications.types;
    let url = null;
    let notificationObj = null;
    switch (element.type) {
      case 5:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "studentUploadedDraft"
        ); //Writer
        url = Helper.getTranslatedStr(
          Helper.getObjProp(notificationObj, "url"),
          {
            "{ studentId }": element.meta_data.studentUser.id,
            "{ documentId }": element.meta_data.studentProgramDocument.id,
            "{ collegeId }": element.meta_data.shortlistedStudentProgram.id,
          }
        );
        break;
      case 7:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "studentFilledCollegeLoginDetails"
        ); //Admin & Counsellor
        url = Helper.getObjProp(notificationObj, "url");
        if (this.authUser.is_admin) {
          url = Helper.getTranslatedStr(url.forAdmin, {
            "{ studentId }": element.meta_data.studentUser.id,
            "{ collegeId }": element.meta_data.shortlistedStudentProgram.id,
          });
        } else if (this.authUser.is_counsellor) {
          url = Helper.getTranslatedStr(url.forCounsellor, {
            "{ studentId }": element.meta_data.studentUser.id,
            "{ collegeId }": element.meta_data.shortlistedStudentProgram.id,
          });
        }
        break;
      case 10:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "studentMarkedDraftFinal"
        ); // Writer
        url = Helper.getTranslatedStr(
          Helper.getObjProp(notificationObj, "url"),
          {
            "{ studentId }": element.meta_data.studentUser.id,
            "{ documentId }": element.meta_data.studentProgramDocument.id,
            "{ collegeId }": element.meta_data.shortlistedStudentProgram.id,
          }
        );
        break;
      case 13:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "newStudentAssigned"
        ); // Counsellor
        url = Helper.getTranslatedStr(
          Helper.getObjProp(notificationObj, "url"),
          {
            "{ studentId }": element.meta_data.studentUser.id,
          }
        );
        break;
      case 14:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "collegeApprovedByStudent"
        ); // Counsellor
        url = Helper.getTranslatedStr(
          Helper.getObjProp(notificationObj, "url"),
          {
            "{ studentId }": element.meta_data.studentUser.id,
            "{ collegeId }": element.meta_data.shortlistedStudentProgram.id,
          }
        );
        break;
      case 15:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "collegesAssignedToCounsellor"
        ); // Counsellor
        url = Helper.getObjProp(notificationObj, "url");
        break;
      case 16:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "newStudentDocumentAssigned"
        ); // Writer
        url = Helper.getTranslatedStr(
          Helper.getObjProp(notificationObj, "url"),
          {
            "{ studentId }": element.meta_data.studentUser.id,
          }
        );
        break;

      case 17:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "newStudentFilledEnrolmentDetails"
        ); // Admin
        url = Helper.getTranslatedStr(
          Helper.getObjProp(notificationObj, "url"),
          {
            "{ studentId }": element.meta_data.studentUser.id,
          }
        );
        break;

      case 18:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "counsellorApprovesStudentsCollege"
        ); //Admin & Writer Manager
        url = Helper.getObjProp(notificationObj, "url");
        if (this.authUser.is_admin) {
          url = Helper.getTranslatedStr(url.forAdmin, {
            "{ studentId }": element.meta_data.studentUser.id,
            "{ collegeId }": element.meta_data.shortlistedStudentProgram.id,
          });
        } else if (this.authUser.is_counsellor) {
          url = Helper.getTranslatedStr(url.forCounsellor, {
            "{ studentId }": element.meta_data.studentUser.id,
            "{ collegeId }": element.meta_data.shortlistedStudentProgram.id,
          });
        }
        break;

      case 20:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "newStudentAssignedToInterviewTrainer"
        ); // Interview Trainer
        if (element.meta_data.collegeProgram.meta_data.type == 0) {
          url = Helper.getTranslatedStr(
            Helper.getObjProp(notificationObj, "url"),
            {
              "{ studentId }": element.meta_data.studentUser.id,
              "{ collegeId }": element.meta_data.shortlistedStudentProgram.id,
            }
          );
        } else {
          url = Helper.getTranslatedStr(
            Helper.getObjProp(notificationObj, "urlWithQuery"),
            {
              "{ studentId }": element.meta_data.studentUser.id,
              "{ collegeId }": element.meta_data.shortlistedStudentProgram.id,
            }
          );
        }
        break;

      case 24:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "newStudentAssignedToVisaCounsellor"
        ); // Visa Counsellor
        if (element.meta_data.collegeProgram.meta_data.type == 0) {
          url = Helper.getTranslatedStr(
            Helper.getObjProp(notificationObj, "url"),
            {
              "{ studentId }": element.meta_data.studentUser.id,
              "{ collegeId }": element.meta_data.shortlistedStudentProgram.id,
            }
          );
        } else {
          url = Helper.getTranslatedStr(
            Helper.getObjProp(notificationObj, "urlWithQuery"),
            {
              "{ studentId }": element.meta_data.studentUser.id,
              "{ collegeId }": element.meta_data.shortlistedStudentProgram.id,
            }
          );
        }
        break;

      case 25:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "studentUpdatedInterviewResult"
        ); // Interview Trainer
        if (element.meta_data.collegeProgram.meta_data.type == 0) {
          url = Helper.getTranslatedStr(
            Helper.getObjProp(notificationObj, "url"),
            {
              "{ studentId }": element.meta_data.studentUser.id,
              "{ collegeId }": element.meta_data.shortlistedStudentProgram.id,
            }
          );
        } else {
          url = Helper.getTranslatedStr(
            Helper.getObjProp(notificationObj, "urlWithQuery"),
            {
              "{ studentId }": element.meta_data.studentUser.id,
              "{ collegeId }": element.meta_data.shortlistedStudentProgram.id,
            }
          );
        }
        break;

      case 26:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "studentUpdatedVisaResult"
        ); // Visa Counsellor
        if (element.meta_data.collegeProgram.meta_data.type == 0) {
          url = Helper.getTranslatedStr(
            Helper.getObjProp(notificationObj, "url"),
            {
              "{ studentId }": element.meta_data.studentUser.id,
              "{ collegeId }": element.meta_data.shortlistedStudentProgram.id,
            }
          );
        } else {
          url = Helper.getTranslatedStr(
            Helper.getObjProp(notificationObj, "urlWithQuery"),
            {
              "{ studentId }": element.meta_data.studentUser.id,
              "{ collegeId }": element.meta_data.shortlistedStudentProgram.id,
            }
          );
        }
        break;

      case 28:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "studentRequiresInterviewAssistance"
        ); //Admin & Interview Trainer Manager
        url = Helper.getObjProp(notificationObj, "url");
        if (this.authUser.is_admin) {
          url = Helper.getTranslatedStr(url.forAdmin, {
            "{ studentId }": element.meta_data.studentUser.slug,
            "{ collegeId }": element.meta_data.shortlistedStudentProgram.uuid,
          });
        } else if (this.authUser.is_interview_manager) {
          url = Helper.getTranslatedStr(url.forInterviewManager, {
            "{ studentId }": element.meta_data.studentUser.slug,
            "{ collegeId }": element.meta_data.shortlistedStudentProgram.uuid,
          });
        }
        break;

      case 29:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "studentRequiresVisaAssistance"
        ); //Admin & Interview Trainer Manager
        url = Helper.getObjProp(notificationObj, "url");
        if (this.authUser.is_admin) {
          url = Helper.getTranslatedStr(url.forAdmin, {
            "{ studentId }": element.meta_data.studentUser.slug,
            "{ collegeId }": element.meta_data.shortlistedStudentProgram.uuid,
          });
        } else if (this.authUser.is_interview_manager) {
          url = Helper.getTranslatedStr(url.forInterviewManager, {
            "{ studentId }": element.meta_data.studentUser.slug,
            "{ collegeId }": element.meta_data.shortlistedStudentProgram.uuid,
          });
        }
        break;
      //newAppointmentCreatedByStudent
      case 36:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "newAppointmentCreatedByStudent"
        );
        url = Helper.getObjProp(notificationObj, "url");
        if (this.authUser.is_counsellor) {
          url = Helper.getTranslatedStr(url.forCounsellor);
        }
        break;

      //requestToAssignWriterByCounsellor
      case 41:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "requestToAssignWriterByCounsellor"
        );
        url = Helper.getObjProp(notificationObj, "url");
        if (this.authUser.is_writer_manager) {
          url = Helper.getTranslatedStr(url.forCounsellingManager, {
            "{ studentId }": element.meta_data.studentUser.slug,
          });
        }
        break;

      //shortlistedProgramUpdatedByCounsellorManager
      case 42:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "shortlistedProgramUpdatedByCounsellorManager"
        );
        url = Helper.getObjProp(notificationObj, "url");
        if (this.authUser.is_counsellor) {
          url = Helper.getTranslatedStr(url.forCounsellor);
        }
        break;

      //collegesShortlistedByCounsellorManager
      case 43:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "collegesShortlistedByCounsellorManager"
        );

        url = Helper.getObjProp(notificationObj, "url");
        if (this.authUser.is_counsellor) {
          url = Helper.getTranslatedStr(url.forCounsellor, {
            "{ studentId }": element.meta_data.studentUser.slug,
          });
        }
        break;

      //collegeDisapprovedByCounsellorManager
      case 44:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "collegeDisapprovedByCounsellorManager"
        );

        url = Helper.getObjProp(notificationObj, "url");
        if (this.authUser.is_counsellor) {
          url = Helper.getTranslatedStr(url.forCounsellor, {
            "{ studentId }": element.meta_data.studentUser.slug,
          });
        }
        break;

      // collegeApprovedByCounsellorManager
      case 45:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "collegeApprovedByCounsellorManager"
        );
        url = Helper.getObjProp(notificationObj, "url");
        if (this.authUser.is_counsellor) {
          url = Helper.getTranslatedStr(url.forCounsellor, {
            "{ studentId }": element.meta_data.studentUser.slug,
          });
        }
        break;

      // collegeDeletedByCounsellorManager
      case 46:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "collegeDeletedByCounsellorManager"
        );
        url = Helper.getObjProp(notificationObj, "url");
        if (this.authUser.is_counsellor) {
          url = Helper.getTranslatedStr(url.forCounsellor, {
            "{ studentId }": element.meta_data.studentUser.slug,
          });
        }
        break;

      //collegesSentForQualityCheck
      case 47:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "collegesSentForQualityCheck"
        );
        url = Helper.getObjProp(notificationObj, "url");
        if (this.authUser.is_counsellor_manager) {
          url = Helper.getTranslatedStr(url.forCounsellingManager, {
            "{ studentId }": element.meta_data.studentUser.slug,
          });
        }
        break;

      case 48:
        notificationObj = Helper.getObjProp(
          dbNotificationTypes,
          "collegeProgramSentForReview"
        );
        url = 
        Helper.getObjProp(notificationObj, "url");
        if (this.authUser.is_counsellor_manager) {
          url = Helper.getTranslatedStr(url.forCounsellingManager, {
            "{ counsellorId }": element.meta_data.counsellorUser.slug,
          });   
        }
        break;

      case 49: 
      notificationObj = Helper.getObjProp(
        dbNotificationTypes,
        "enrollmentFormUpdated"
      );
      url = Helper.getObjProp(notificationObj, "url");
      if (this.authUser.is_counsellor) {
        url = Helper.getTranslatedStr(url.forCounsellor, {
          "{ studentId }": element.meta_data.studentUser.slug,
        });
      }
      break;

      default:
        url = "colleges/shortlisted-colleges";
        break;
    }


    this.router.navigate([url]);
  }

  checkRead(notification) {
    return notification.is_read == false;
  }

  getUrl(element) {
    let allowedProfilePhoto = settings.notificationsWithProfilePic;
    if (allowedProfilePhoto.includes(element.type)) {
      return element.meaningful_details.image;
    } else {
      return "assets/images/check-icon.svg";
    }
  }

  goToNotifications() {
    if (this.authUser.is_admin) {
      this.router.navigate(["/manager/notifications"]);
    } else if (this.authUser.is_counsellor) {
      this.router.navigate(["/counsellor/notifications"]);
    } else if (this.authUser.is_counsellor_manager) {
      this.router.navigate(["/counselling-manager/notifications"]);
    } else if (this.authUser.is_writer) {
      this.router.navigate(["/writer/notifications"]);
    } else if (this.authUser.is_writer_manager) {
      this.router.navigate(["/writer-manager/notifications"]);
    } else if (this.authUser.is_interview_manager) {
      this.router.navigate(["/interview-trainer-manager/notifications"]);
    } else if (this.authUser.is_interview_trainer) {
      this.router.navigate(["/interview-trainer/notifications"]);
    } else if (this.authUser.is_visa_counsellor_manager) {
      this.router.navigate(["/visa-counsellor-manager/notifications"]);
    } else if (this.authUser.is_visa_counsellor) {
      this.router.navigate(["/visa-counsellor/notifications"]);
    } else if (this.authUser.is_mentor) {
      this.router.navigate(["/mentor/notifications"]);
    }

    // this.router.navigate(['/counsellor/notifications']);
    // if(notification.type === '1'){
    //   this.readNotification(notification.id);
    //   this.router.navigate(['chat']);
    // }else{
    //   this.readNotification(notification.id);
    //   this.router.navigate(['individual-college/true/'+ notification.meta_data.college_id +'/single-document/'+ notification.meta_data.document_id]);
    // }
  }

  readNotification(notificationId) {
    this.userService.readNotifications(notificationId).subscribe(
      (res) => {
        this.getNotifications();
        console.log(res);
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
