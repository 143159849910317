import { Component, Input } from "@angular/core";

@Component({
  selector: "filler-block",
  template: `
        <div *ngIf="shouldShow" class="new-student-wrapper d-block">
            <div class="row">
                <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <img [src]="'./assets/images/' + getIcon" alt="" class="icon">
                            <div class="title">
                                {{ defaultHeading }}
                            </div>
                            <p class="text">
                                {{ defaultText }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `
})
export class FillerBlock {
    @Input() shouldShow: boolean = false;
    @Input() icon: string = 'empty';
    @Input() defaultText: string = '';
    @Input() defaultHeading: string = 'No Results!';

    get getIcon() {
        switch(this.icon) {
            case 'sample':
                return 'icons/sample-icon.svg';
            case 'empty':
                return 'empty-state.svg';    
            default:
                return 'empty-state.svg';
        }
    }

    constructor() { }
}
