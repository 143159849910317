import { Component, OnInit } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { Toastr } from "../../../plugins/toastr/toastr";
import { Helper } from "../../../utilities/helpers";
import { CollegeProgramService } from "../../../services/college.program.service";
import { NgModel } from "@angular/forms";
import { NgxPaginationModule } from "ngx-pagination";
import { AuthenticationService } from "src/app/core/services/authentication.service";
import { Router } from "@angular/router";

@Component({
  selector: "assign-college-modal",
  templateUrl: "./assign-college.component.html",
  providers: [CollegeProgramService],
})
export class AssignCollegeModal implements OnInit {
  public counsellorId: any;
  public collegePrograms: any = [];
  public paginationData: any;
  public total: number;
  public page: number = 0;
  public loading: boolean = false;
  public per_page: number = 0;
  public collegeProgramToAssign: any = [];
  public collegeTableSelected: any = {};
  public authUser: any;
  public searchInputValue: any = "";
  public isMentor: boolean = false;
  public mentorId: any;

  constructor(
    public modalRef: BsModalRef,
    public toastr: Toastr,
    public collegeProgramService: CollegeProgramService,
    public authService: AuthenticationService,
    public router: Router
  ) {
    this.isMentor = window.location.href.includes("mentor");
  }

  ngOnInit() {
    this._fetchAuthUser();
  }

  _fetchAuthUser() {
    this.authUser = this.authService.authUser;
    if (this.authUser.type == "counsellor-manager") {
      this.getCounsellingManagerCollegeProgramsForCounsellor();
    } else if (this.isMentor) {
      this.getCollegeProgramForMentor();
    } else {
      this.getCollegeProgramsForCounsellor();
    }
  }

  getCollegeProgramsForCounsellor(page = 1, params: Object = {}) {
    this._startLoading();
    params = Helper.cloneObj(
      {
        page: page,
        query: this.searchInputValue,
        filters: "counsellor," + this.counsellorId,
      },
      params
    );
    this.collegeProgramService.getUnassignedUserColleges(params).subscribe(
      (res) => {
        this.paginationData = res.meta.pagination;
        this.total = this.paginationData.total;
        this.per_page = this.paginationData.per_page;
        this.page = this.paginationData.current_page;
        this.collegePrograms = [];
        res.data.map((element) => {
          if (element.meta_data.type == 0) {
            this.collegePrograms.push(element);
          }
        });
        this._stopLoading();
      },
      (error) => {
        this._stopLoading();
      }
    );
  }

  getCollegeProgramForMentor(page = 1, params: Object = {}) {
    console.log("Fetched College Programs By Manager if mentor", this.mentorId);
    this._startLoading();
    params = Helper.cloneObj(
      {
        page: page,
        query: this.searchInputValue,
        filters: "mentor," + this.mentorId,
      },
      params
    );
    this.collegeProgramService.getUserColleges(params).subscribe(
      (res) => {
        this.paginationData = res.meta.pagination;
        this.total = this.paginationData.total;
        this.per_page = this.paginationData.per_page;
        this.page = this.paginationData.current_page;
        this.collegePrograms = [];
        res.data.map((element) => {
          if (element.meta_data.type == 0) {
            this.collegePrograms.push(element);
          }
        });
        this._stopLoading();
      },
      (error) => {
        this._stopLoading();
      }
    );
  }

  getCollegePrograms(page = 1, params: Object = {}) {
    if (this.authUser.type == "counsellor-manager") {
      this.getCounsellingManagerCollegeProgramsForCounsellor(page, params);
    } else {
      this.getCollegeProgramsForCounsellor(page, params);
    }
  }

  getCounsellingManagerCollegeProgramsForCounsellor(
    page = 1,
    params: Object = {}
  ) {
    console.log("Fetched College Programs By Counselling Manager");
    this._startLoading();
    params = Helper.cloneObj(
      {
        page: page,
        query: this.searchInputValue,
        filters: "counsellor," + this.counsellorId,
      },
      params
    );
    this.collegeProgramService.getCounsellingManagerColleges(params).subscribe(
      (res) => {
        this.paginationData = res.meta.pagination;
        this.total = this.paginationData.total;
        this.per_page = this.paginationData.per_page;
        this.page = this.paginationData.current_page;
        this.collegePrograms = [];
        res.data.map((element) => {
          if (element.meta_data.type == 0) {
            this.collegePrograms.push(element);
          }
        });
        this._stopLoading();
      },
      (error) => {
        this._stopLoading();
      }
    );
  }

  checkCollegeProgram(event, pid) {
    if (event.target.checked == true) {
      this.collegeProgramToAssign.push(event.target.value);
      this.collegeTableSelected[pid] = true;
      console.log("checked: ", event.target.value);
      console.log("Final Array:  ", this.collegeProgramToAssign);
    } else {
      var index = this.collegeProgramToAssign.indexOf(event.target.value);
      this.collegeProgramToAssign.splice(index, 1);
      console.log("unchecked: ", event.target.value);
      this.collegeTableSelected[pid] = false;
      console.log("Final Array:  ", this.collegeProgramToAssign);
    }
  }

  submit() {
    if (this.collegeProgramToAssign.length == 0) {
      this.toastr.showWarning(
        "In order to assign college program to counsellor, you need to select atleast one college program."
      );
      return;
    }
    this._startLoading();
    let finalCollegePrograms = {
      colleges: this.collegeProgramToAssign,
    };
    if (this.authUser.type == "counsellor-manager") {
      this.assignCollegesByCounsellingManager(finalCollegePrograms);
    } else {
      this.assignCollegesByManager(finalCollegePrograms);
    }
  }

  goToCollege(collegeProgamSlug) {
    if (this.authUser.type == "counsellor-manager") {
      this.router.navigate([
        "counselling-manager/colleges/individual-college/" +
          collegeProgamSlug +
          "/college-details",
      ]);
      this.modalRef.hide();
    } else {
      this.router.navigate([
        "/manager/college-programs/individual-college-program/" +
          collegeProgamSlug +
          "/college-program-students",
      ]);
      this.modalRef.hide();
    }
  }

  assignCollegesByManager(finalCollegePrograms) {
    console.log("By Manager");

    if (this.isMentor) {
      console.log("Mentor submit form", finalCollegePrograms, this.mentorId);
      this.collegeProgramService
        .addMentorColleges(this.mentorId, finalCollegePrograms)
        .subscribe(
          (res) => {
            this.toastr.showSuccess(
              "Successfully assigned colleges to counsellor"
            );
            this.modalRef.hide();
            this._stopLoading();
          },
          (error) => {
            this._stopLoading();
          }
        );
    } else {
      this.collegeProgramService
        .addCounsellorColleges(this.counsellorId, finalCollegePrograms)
        .subscribe(
          (res) => {
            this.toastr.showSuccess(
              "Successfully assigned colleges to counsellor"
            );
            this.modalRef.hide();
            this._stopLoading();
          },
          (error) => {
            this._stopLoading();
          }
        );
    }
  }

  assignCollegesByCounsellingManager(finalCollegePrograms) {
    this.collegeProgramService
      .addCounsellingManagerCounsellorColleges(
        this.counsellorId,
        finalCollegePrograms
      )
      .subscribe(
        (res) => {
          this.toastr.showSuccess(
            "Successfully assigned colleges to counsellor"
          );
          this.modalRef.hide();
          this._stopLoading();
        },
        (error) => {
          this._stopLoading();
        }
      );
  }

  _startLoading() {
    this.loading = true;
  }

  _stopLoading() {
    this.loading = false;
  }
}
