import { Injectable } from "@angular/core";
import * as AWS from "aws-sdk/global";
import * as S3 from "aws-sdk/clients/s3";
import { environment } from "../../../environments/environment";
import { AngularFireDatabase } from "angularfire2/database";
import * as moment from "moment";
import { Helper } from "../utilities/helpers";
import settings from "../config/settings";

@Injectable()
export class UploadFileService {
  constructor(public db: AngularFireDatabase) {}

  message: string = "";
  groupId: string;
  authUser: string;
  appositeUser: string;
  encryptedName: any;
  type: any = 2;

  sendMessage(path, name) {
    this.message = name;
    const timestamp = new Date().toLocaleString();
    const formattedTimestamp = Helper.formatTimestamp(timestamp);
    this.db.database.ref(`/conversations/${this.groupId}`).push({
      sender_id: this.authUser,
      receiver_id: this.appositeUser,
      message: this.message,
      timestamp: formattedTimestamp,
      // timestamp: moment().format('LLL'),
      type: this.type,
      document_name: path,
    });
  }

  uploadfile(file, dataObject) {
    console.log("this is working fine");
    this.authUser = dataObject.authUser;
    this.appositeUser = dataObject.appositeUser;
    this.groupId = dataObject.groupId;

    const bucket = new S3({
      accessKeyId: settings.aws.s3.accessKeyId,
      secretAccessKey: settings.aws.s3.secretAccessKey,
      region: "ap-south-1",
    });
    console.log("this is working fine middle");

    let now = new Date();
    let uniqueId = now.valueOf();
    this.encryptedName = uniqueId + file.name;
    const params = {
      Bucket: this.appositeUser
        ? `${settings.aws.s3.bucket}/${settings.aws.s3.folder.chat}`
        : `${settings.aws.s3.bucket}/${settings.aws.s3.folder.application_materials}`,
      Key: this.encryptedName,
      Body: file,
    };
    console.log("this is working fine after middle");
    console.log("====================+>Params", params);

    return bucket.upload(params, (err, data) => {
      if (err) {
        console.log("There was an error uploading your file: ", err);
        return false;
      }

      if (this.appositeUser) {
        this.sendMessage(this.encryptedName, file.name);
      }
      console.log(data);

      return data;
    });
  }
}
