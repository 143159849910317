export class formModel {
  id: any;
  class: any;
  activity: any;
  status: any;
  jamboree_comments: any;
  student_comments: any;
  target_date: any;
  Remarks: any;
}
