import {Component, TemplateRef} from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
  selector: 'request-writer-modal',
  templateUrl: './request-writer.component.html'
})
export class RequestWriterModal {

  constructor(public modalRef: BsModalRef) {}

  selected: string;
  public actions:Array<string> = [
    'All Documents',
    'Application Material Sent',
    'Welcome Call',
    'Any Other Action'
  ];

}