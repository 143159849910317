import * as moment from "moment";
import { Toastr } from "../../../plugins/toastr/toastr";
import { StudentService } from "src/app/core/services/student.service";

import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, ModalOptions } from "ngx-bootstrap";
import {
    FormGroup,
    FormBuilder,
    Validators,
    ReactiveFormsModule,
  } from "@angular/forms";

import { formModel } from "../../model/formModel.model";
import { ActivatedRoute, Router } from "@angular/router";
import { testingOptions,englishProficiencyOptions,
  academicsOptions,
  cocirricularOptions,
  extracurricularActivitiesOptions,
  communityServiceOptions,
  projectOptions,
  internshipOptions, } from "src/app/core/utilities/recommendationOptions";
import { Helper } from "src/app/core/utilities/helpers";

@Component({
  selector: "update-project-recommendation-modal",
  templateUrl: "./update-project-recommendation.component.html",
  
  providers: [StudentService, Toastr],
})
export class UpdateProjectRecommendationModal implements OnInit {
  @ViewChild("dp1") datepicker: any;

  public activityFormGroup: FormGroup;
  public initialState: any;
  public loading: boolean = false;
  public notEditMode: boolean = true;
  activityForm: any = [];
  formModel = new formModel();
  public maxDate: any = new Date();
  public allActivities: any = [];
  public totalNumberOfActivities: number;
  public studentId: any;
  public studentSlug: any;
  public task: any;
  public editProjectDetails: boolean = false;
  public activityType: string;
  // forms for every activity
  testingForm: any = [];
  englishProficiencyForm: any = [];
  academicForm: any = [];
  cocirricularForm: any = [];
  extraCurricularForm: any = [];
  communityServiceForm: any = [];
  projectForm: any = [];
  internshipForm: any = [];

  // arrays for every activity
  public allTestingActivites: any = [];
  public allEnglishProficiency: any = [];
  public allAcademicActivities: any = [];
  public allCoCirricularActivites: any = [];
  public allExtraCurricularActivites: any = [];
  public allCommunityServiceActivites: any = [];
  public allProjectActivites: any = [];
  public allInternshipActivites: any = [];
  
  public newActivity:any;

  public testingOptionsDropdowns = testingOptions;
  public academicsOptionsDropdowns = academicsOptions;
  public cocirricularOptionsDropdowns = cocirricularOptions;
  public extracurricularActivitiesOptionsDropdowns =
    extracurricularActivitiesOptions;
  public englishProficiencyOptionsDropdowns = englishProficiencyOptions;

  public communityServiceOptionsDropdowns = communityServiceOptions;
  public projectOptionsDropdowns = projectOptions;
  public internshipOptionsDropdowns = internshipOptions;



  formData: any = {};
  isModalOpen: boolean = true;
 

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private studentService: StudentService, 
    private toastr: Toastr,
    public modalRef: BsModalRef,
    public options: ModalOptions) {
      this.initialState = this.options.initialState;
      this.studentId = this.initialState.studentId;
      this.studentSlug = this.initialState.slug;
      this.task = this.initialState.task;
      this.editProjectDetails = this.initialState.editProjectDetails;
      this.activityType = this.initialState.activityType;
      this.newActivity = {
        class:'',
        activity:'',
        status:'',
        jamboree_comments: '',
        target_date: null,
        Remarks:''
      }
    }

  ngOnInit() {
    this.formModel = new formModel();
    this._getParams();
  }

  _getParams() {
    this.studentSlug.subscribe(
      (params) => (this.studentId = params.studentId)
    );
    this._getStudentDetails();
  }

  _getStudentDetails() {
    this._startLoading();
    this.studentService.getCounsellorStudentDetails(this.studentId).subscribe(
      (res) => {
        this.studentId = res.owner.id;
        this._fetchData();
        this._stopLoading();
      },
      (error) => {
        this._stopLoading();
      }
    );
  }

  _fetchData() {
    this._startLoading();
    let arr = [];

    this.studentService
      .getActivityCounsellor(this.studentId)
      .subscribe((res: any) => {
        this._stopLoading();
        this.allActivities = [];
        res.data.map((activity) => {
          this.allActivities.push(
            Helper.cloneObj(activity, this._getOtherDetails(activity))
          );
        });
        this._filterationOfActivities();
      });
  }

  _getOtherDetails(activity) {
    if (activity.target_date != null) {
      let tempObj = {
        target_date: moment(activity.target_date).format("LL"),
        edit: false,
      };
      return tempObj;
    }
    let tempObj = {
      edit: false,
    };
    return tempObj;
  }

    _filterationOfActivities() {
      this.allTestingActivites = this._returnActivityArrayData(1);
      this.allAcademicActivities = this._returnActivityArrayData(2);
      this.allCoCirricularActivites = this._returnActivityArrayData(3);
      this.allExtraCurricularActivites = this._returnActivityArrayData(4);
      this.allCommunityServiceActivites = this._returnActivityArrayData(5);
      this.allProjectActivites = this._returnActivityArrayData(6);
      this.allInternshipActivites = this._returnActivityArrayData(7);
      this.allEnglishProficiency = this._returnActivityArrayData(8);
    }

    _checkActivity(activity, activitiesArray) {
      for (let i = 0; i < activitiesArray.length; i++) {
        if (
          activitiesArray[i].toLowerCase() === activity.activity.toLowerCase()
        ) {
          return activitiesArray;
        }
      }
      activitiesArray.push(activity.activity);
      return activitiesArray;
    }
    _addActivites(tagId, activity) {
      switch (tagId) {
        case 1:
          this.testingOptionsDropdowns = this._checkActivity(
            activity,
            this.testingOptionsDropdowns
          );
          break;
        case 2:
          this.academicsOptionsDropdowns = this._checkActivity(
            activity,
            this.academicsOptionsDropdowns
          );
          break;
        case 3:
          this.cocirricularOptionsDropdowns = this._checkActivity(
            activity,
            this.cocirricularOptionsDropdowns
          );
          break;
        case 4:
          this.extracurricularActivitiesOptionsDropdowns = this._checkActivity(
            activity,
            this.extracurricularActivitiesOptionsDropdowns
          );
          break;
        case 5:
          this.communityServiceOptionsDropdowns = this._checkActivity(
            activity,
            this.communityServiceOptionsDropdowns
          );
          break;
        case 6:
          this.projectOptionsDropdowns = this._checkActivity(
            activity,
            this.projectOptionsDropdowns
          );
          break;
        case 7:
          this.internshipOptionsDropdowns = this._checkActivity(
            activity,
            this.internshipOptionsDropdowns
          );
          break;
        case 8:
          this.englishProficiencyOptionsDropdowns = this._checkActivity(
            activity,
            this.englishProficiencyOptionsDropdowns
          );
          break;
      }
    }
    _returnActivityArrayData(id) {
      let resultArr = [];
      this.allActivities.map((activity) => {
        if (activity.tags === id) {
          this._addActivites(id, activity);
          resultArr.push(activity);
        }
      });
      return resultArr;
    }
  
    addFormInputs(taskId) {
      this.formModel = new formModel();
      if (taskId === 1) {
        this.testingForm.push(this.formModel);
      }
      if (taskId === 2) {
        this.academicForm.push(this.formModel);
      }
      if (taskId === 3) {
        this.cocirricularForm.push(this.formModel);
      }
      if (taskId === 4) {
        this.extraCurricularForm.push(this.formModel);
      }
      if (taskId === 5) {
        this.communityServiceForm.push(this.formModel);
      }
      if (taskId === 6) {
        this.projectForm.push(this.formModel);
      }
      if (taskId === 7) {
        this.internshipForm.push(this.formModel);
      }
      if (taskId === 8) {
        this.englishProficiencyForm.push(this.formModel);
      }
    }
  
    updateActivity(activity, activityId) {
      this._startLoading();
      if (activity.target_date != null) {
        activity.target_date = moment(activity.target_date).format("L");
      }
      if (activity.activity == "Others") {
        activity.activity = activity.activityInput;
        delete activity.activityInput;
      }
      const id = activity.id;
      delete activity["id"];
      delete activity["tags"];
      this.studentService
        .getUpdateActivityCounsellor(this.studentId, id, activity)
        .subscribe((res: any) => {
          this.toastr.showSuccess("Recommendation Activity Updated");
          window.location.reload(); 
          this._stopLoading();
          this._fetchData();
        });
        (err) => this._stopLoading()
    }

    selectedOthers(activity) {
      console.log(activity.target.value);
    }

    submit(tagId, formData) {
      this._startLoading();
      if (formData.target_date != undefined) {
        formData.target_date = moment(formData.target_date).format(
          "L"
        );
      }
      if (formData.activity == "Others") {
        formData.activity = formData.activityInput;
        delete formData.activityInput;
      }

     
      this.studentService
        .getAddActivityCounsellor(this.studentId, tagId, formData)
        .subscribe(
          (res: any) => {
            this.toastr.showSuccess("Added a new Activity");
            window.location.reload();
            this._stopLoading();
            this._fetchData();
          },
          (err) => this._stopLoading()
        );
      let arrForm = [];
      switch (tagId) {
        case 1:
          arrForm = this.newActivity;
          this.newActivity = arrForm;
  
          break;
        case 2:
          arrForm = this.newActivity;
          this.newActivity = arrForm;
          break;
        case 3:
          arrForm = this.newActivity;
          this.newActivity = arrForm;
          break;
        case 4:
          arrForm = this.newActivity;
          this.newActivity = arrForm;
          break;
        case 5:
          arrForm = this.newActivity;
          this.newActivity = arrForm;
          break;
        case 6:
          arrForm = this.newActivity;
          this.newActivity = arrForm;
          break;
        case 7:
          arrForm = this.newActivity;
          this.newActivity = arrForm;
          break;
        case 8:
          arrForm = this.newActivity;
          this.newActivity = arrForm;
          break;
      }
    }
  
    finalSubmit() {}
  
    onToggleEditMode(activity) {
      activity.edit = !activity.edit;
    }
  
    close(event) {
      if (event.relatedTarget) {
        if (
          event.relatedTarget.parentNode.nodeName !==
          "BS-DATEPICKER-NAVIGATION-VIEW"
        ) {
          this.datepicker.hide();
        }
      }
    }

  _startLoading() {
    this.loading = true;
  }

  _stopLoading() {
    this.loading = false;
  }
}

