import { Component } from "@angular/core";
import { setTheme } from "ngx-bootstrap/utils";
import { UserService } from "./core/services/user.services";
import { MessagingService } from "./core/services/messaging.service";
import { AuthenticationService } from "./core/services/authentication.service";

@Component({
  selector: "body",
  template: "<router-outlet></router-outlet>",
  styleUrls: ["./app.component.css"],

  providers: [UserService],
})
export class AppComponent {
  public message: any;

  constructor(
    public userService: UserService,
    public messagingService: MessagingService,
    public authService: AuthenticationService
  ) {
    setTheme("bs4");
    this.userService.getConfigData().subscribe((res) => {
      this.userService.setConfig(res.data);
    });
    const user = this.authService.authUser;
    if (user) {
      this.messagingService.requestPermission(user.id);
      this.messagingService.receiveMessage();
      this.message = this.messagingService.currentMessage;
    }
  }
}
