import { Component, TemplateRef, OnInit } from "@angular/core";
// import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { Toastr } from "../../../plugins/toastr/toastr";
import { Validators, FormGroup, FormBuilder } from "@angular/forms";
import { CustomValidator } from "../../../utilities/validator/custom-validator";
import * as moment from "moment";
import { StudentService } from "src/app/core/services/student.service";
import { Helper } from "../../../utilities/helpers";
// Storage for a purpose
import { Storage } from "../../../utilities/storage";
import { ModalOptions } from "ngx-bootstrap";

// for tags
import { Observable } from "rxjs";

@Component({
  selector: "add-roadmap-modal",
  templateUrl: "./add-roadmap.component.html",
  providers: [StudentService, Toastr],
})
export class AddRoadmapModal implements OnInit {
  public loading: boolean = false;
  studentId: any;
  submitted: boolean = false;
  roadmapForm: FormGroup;
  public minDate: any = new Date();
  public errorMsg: any = "";
  public tasks: any = [];
  public editRoadmapFlag: boolean = false;
  public taskId: number;
  public particularTask: any = [];
  public initialState: any;
  public editByCounsellor: boolean = false;
  public editByMentor: boolean = false;
  public timer: any = 0;
  public tags: Observable<Array<any>>;
  public tag_id = new Set();
  public defaultTag: any = [];

  constructor(
    public studentService: StudentService,
    public toastr: Toastr,
    public modalRef: BsModalRef,
    private formBuilder: FormBuilder,
    public storage: Storage,
    public options: ModalOptions
  ) {
    this.initialState = this.options.initialState;
    this.studentId = this.initialState.studentId;
    this.editByCounsellor = this.initialState.editByCounsellor;
    this.taskId = this.initialState.taskId;
    this.editByMentor = this.initialState.editByMentor;
    if (this.editByCounsellor || this.editByMentor) {
      this.editRoadmapFlag = true;
      this.getRoadmap();
    }
    console.log(
      "initial state inside admin control form",
      this.editRoadmapFlag,
      this.taskId
    );
  }

  ngOnInit() {
    this._initRoadmapForm();
    this._fetchTagsFromServer();
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.roadmapForm.controls;
  }

  _initRoadmapForm() {
    this.roadmapForm = this.formBuilder.group({
      title: ["", [Validators.required, CustomValidator.lessThenLength(254)]],
      description: [
        "",
        [Validators.required, CustomValidator.lessThenLength(254)],
      ],
      priority: ["", Validators.required],
      class: [""],
      tags: [""],
      monthly_check_in: [0],
      deadline: ["", [Validators.required]],
    });
  }

  getRoadmap() {
    if (this.editByCounsellor) {
      this.getRoadmapCounsellor();
    }
    if (this.editByMentor) {
      this.getRoadmapMentor();
    }
  }

  getRoadmapCounsellor(params = {}) {
    params = Helper.cloneObj(
      {
        includes: "created_by,tags",
      },
      params
    );
    this._startLoading();
    this.studentService
      .getCounsellorStudentRoadmap(this.studentId, params)
      .subscribe(
        (res) => {
          console.log("RoadMap:::::::", res);
          this.tasks = [];
          res.data.map((task) => {
            this.tasks.push(Helper.cloneObj(task, this._getOtherDetails(task)));
          });
          if (this.editRoadmapFlag) {
            this.extractTasktoEdit();
            this._setDefaultTag();
          }
          this._stopLoading();
        },
        (error) => {
          this._stopLoading();
        }
      );
  }

  // need to make getroadmap data mentor
  getRoadmapMentor(params = {}) {
    params = Helper.cloneObj(
      {
        includes: "created_by,tags",
      },
      params
    );
    this._startLoading();
    this.studentService
      .getMentorStudentRoadmap(this.studentId, params)
      .subscribe(
        (res: any) => {
          // data related to only roadmap task
          console.log("RoadMap::::::: GET roadmap data", res);
          this.tasks = [];
          res.data.map((task) => {
            this.tasks.push(Helper.cloneObj(task, this._getOtherDetails(task)));
          });
          if (this.editRoadmapFlag) {
            this.extractTasktoEdit();
            this._setDefaultTag();
          }
          // take only relevent data from roadmap data
          console.log("TAska array data ", this.tasks);
          this._stopLoading();
        },
        (error) => {
          this._stopLoading();
        }
      );
  }

  _getOtherDetails(task) {
    let date = task.deadline.split("-");
    let tempObject = { deadline: date[1] + "/" + date[2] + "/" + date[0] };

    return tempObject;
  }

  submit() {
    this.roadmapForm.patchValue({
      tags: Array.from(this.tag_id.values()),
    });
    this.submitted = true;
    console.log(this.roadmapForm.value, "Roadmap Form values for submission");
    if (this.roadmapForm.invalid) {
      console.log("error");
      return;
    }
    // return;

    if (this.editByCounsellor) {
      this.updateRoadmapCounsellor(this.roadmapForm.value);
    } else if (this.editByMentor) {
      this.updateRoadmapMentor(this.roadmapForm.value);
    } else {
      // add roadmap functionality
      if (this.storage.get("authUser").type == "mentor") {
        this.submitMentorAddRoadmap(this.roadmapForm.value);
      } else {
        this.finalSubmit(this.roadmapForm.value);
      }
    }
  }

  updateRoadmapMentor(dataObject) {
    dataObject.deadline = Helper.changeDateFormat(dataObject);
    // dataObject.deadline = moment(dataObject.deadline).format("L");
    console.log("Mentor updated", dataObject);
    this._startLoading();
    this.studentService
      .getUpdateRoadmapMentor(this.studentId, this.taskId, dataObject)
      .subscribe(
        (res: any) => {
          this.toastr.showSuccess("Sucessfully updated roadmap");
          this._stopLoading();
          this.modalRef.hide();
          console.log("Res after roadmap update mentor", res);
        },
        (error) => {
          this._stopLoading();
          console.log(error);
        }
      );
  }

  updateRoadmapCounsellor(dataObject) {
    dataObject.deadline = Helper.changeDateFormat(dataObject);
    console.log("Before moment", dataObject);
    // dataObject.deadline = moment(dataObject.deadline).format("L");
    console.log("After moment", dataObject);
    this._startLoading();
    this.studentService
      .getUpdateRoadmapCounsellor(this.studentId, this.taskId, dataObject)
      .subscribe(
        (res: any) => {
          this.toastr.showSuccess("Successfully updated roadmap");
          this._stopLoading();
          this.modalRef.hide();
          console.log(res, "Res afer roadmap updated counsellor");
        },
        (error) => {
          this._stopLoading();
          console.log(error);
        }
      );
  }

  submitMentorAddRoadmap(dataObject) {
    dataObject.deadline = moment(dataObject.deadline).format("L");
    console.log("Form data of roadmap to be submitted", dataObject);
    this._startLoading();
    // api call kar lo submit kar do
    this.studentService
      .getAddMentorStudentRoadmap(this.studentId, dataObject)
      .subscribe(
        (res: any) => {
          this.toastr.showSuccess("Successfully added new task");
          this._stopLoading();
          this.modalRef.hide();
          console.log(res);
        },
        (error) => {
          this._stopLoading();
          console.log(error);
        }
      );
  }

  finalSubmit(dataObject) {
    dataObject.deadline = moment(dataObject.deadline).format("L");
    this._startLoading();
    this.studentService
      .addCounsellorStudentRoadmap(this.studentId, dataObject)
      .subscribe(
        (res) => {
          this.toastr.showSuccess("Successfully added new task");
          this._stopLoading();
          this.modalRef.hide();
          console.log(res);
        },
        (error) => {
          this._stopLoading();
          console.log(error);
        }
      );
  }

  extractTasktoEdit() {
    this.particularTask = Helper.searchObjById(this.tasks, this.taskId);
    this.particularTask = this.particularTask[0];
    console.log(this.particularTask, "Particular Task ");
  }

  _startLoading() {
    this.loading = true;
  }

  _stopLoading() {
    this.loading = false;
  }

  //======================================================================
  // Tags selection functions
  //======================================================================

  _refreshTagValue(value: any): void {}
  _selectedTag(value: any): void {
    this.tag_id.clear();
    this.tag_id.add(value.id);
    console.log(this.tag_id);
  }

  _removedTag(value: any): void {
    console.log(value);
    this.tag_id.delete(value.id);
    console.log(this.tag_id);
  }

  _typedTag(value: any): void {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      let obj = {
        query: value,
      };
      this._fetchTagsFromServer(obj);
    }, 2000);
  }

  _setDefaultTag() {
    let assignedTag = {};

    // for api fetching.
    for (let i = 0; i < this.particularTask.tags.length; i++) {
      assignedTag = {
        id: Helper.getObjProp(this.particularTask.tags[i], "id"),
        text: Helper.getObjProp(this.particularTask.tags[i], "name"),
      };
      this.tag_id.add(Helper.getObjProp(this.particularTask.tags[i], "id"));
      this.defaultTag = [...this.defaultTag, assignedTag];
    }
    // console.log("Set deafault function started working",this.defaultTag);
  }

  _fetchTagsFromServer(paramsObj: Object = {}) {
    this.studentService.getRoadmapTags().subscribe((res: any) => {
      console.log("Res dummy data", res);
      let data = Helper.getObjProp(res, "data");
      let obj = [];
      for (let i = 0; i < data.length; i++) {
        obj.push({
          id: data[i].id,

          text: `${data[i].name}`,
        });
      }
      this.tags = Observable.of(obj).map((res) => res);
      console.log(this.tags, "Tags");
    });
  }
}
