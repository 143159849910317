import {Component, TemplateRef, OnInit} from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { UserService } from '../../../services/user.services';
import { Toastr } from '../../../plugins/toastr/toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidator } from 'src/app/core/utilities/validator/custom-validator';
import { CollegeService } from 'src/app/core/services/college.service';

@Component({
  selector: 'write-feedback-modal',
  templateUrl: './write-feedback.component.html',
  providers:[ Toastr, CollegeService ]
})
export class WriteFeedbackModal implements OnInit {
  // title: string;
  // closeBtnName: string;
  // list: any[] = [];
  studentId: any;
  interviewId: any;
  interviewSessionId: any;
  collegeTypeShortlisted: any;
  public disable: boolean = true;
  public error: boolean = false;
  public success: boolean = false;
  public loading: boolean = false;
  query: string = '';
  successMsg: String =  'Thank You, We Will Contact You Shortly.';
  feedbackForm: FormGroup;
  submitted: boolean = false;

  constructor(
    public userService: UserService, 
    public toastr: Toastr, 
    public modalRef: BsModalRef,
    private formBuilder: FormBuilder,
    public collegeService: CollegeService
    ) {

    }

  ngOnInit(){
    this._initFeedbackForm();
  }

  // convenience getter for easy access to form fields
  get f() { return this.feedbackForm.controls; }


  _initFeedbackForm(){
    this.feedbackForm = this.formBuilder.group({
      confidence: ['', [
                Validators.required,
                CustomValidator.number('floatInt100')
              ]],
      field_1: ['', [
                Validators.required,
                CustomValidator.lessThenLength(254)
              ]],
      field_2: ['', [
                Validators.required,
                CustomValidator.lessThenLength(254)
              ]],
      field_3: ['', [
                Validators.required,
                CustomValidator.lessThenLength(254)
              ]]
    });
  }

  submit(){

    this.submitted = true;

    if (this.feedbackForm.invalid) {
      console.log('error');
      return;
    }
    let params = {}
    if(this.collegeTypeShortlisted){
      params = {
        type:1
      }
    }
    this._startLoading();
    this.collegeService.createInterviewTrainerStudentInterviewSessionFeedback(this.studentId, this.interviewId, this.interviewSessionId, this.feedbackForm.value, params).subscribe(
    res =>{
      this.toastr.showSuccess('Succesfully submitted feedback for interview session');
      this.modalRef.hide();
      this._stopLoading();
    }, 
    error => {
      this._stopLoading();
    });
  
  }

  closeModal(modalRef: any) {
    modalRef.hide();
  }

  


  _startLoading() {
    this.loading = true;
  }
  
  _stopLoading() {
    this.loading = false;
  }

}