import { Component, Input } from "@angular/core";

@Component({
  selector: "side-nav-bar",
  templateUrl: 'side-nav-bar.directive.html',
})
export class SideNavBar {
    @Input() shouldShow: boolean = false;
    @Input() icon: string = 'sample';
    @Input() defaultText: string = '';
    @Input() defaultHeading: string = 'No Results!';

    get getIcon() {
        switch(this.icon) {
            case 'sample':
                return 'sample-icon.svg';
            default:
                return 'sample-icon.svg';
        }
    }

    constructor() { }
}
