import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Storage } from '../utilities/storage';
import { UserService } from '../services/user.services';
import { Toastr } from '../plugins/toastr/toastr';

@Injectable()
export class GuestGuard implements CanActivate {

    constructor(
        private router: Router,
        private storage: Storage,
        private userService: UserService,
        private toastr: Toastr,
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        let authUser = this.storage.get('authUser');
        if (authUser) {
            // logged in so redirect to dashboard
            this.userService.setRootPage(authUser, this.toastr);
            return false;
        }

        // logged in so return true
        return true;
    }
}