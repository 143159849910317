import { Component, Input } from '@angular/core';

@Component({
  selector: 'loader-spinner',
  template: `
    <div *ngIf="show()" class="loader-full-circle-small"></div>
  `
})
export class LoaderSpinner {

  @Input() showLoader: boolean = false;

  show() {
    return this.showLoader;
  }

  constructor() { }
}
