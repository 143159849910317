import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Storage } from '../utilities/storage';
import { AuthenticationService } from '../services/authentication.service';
 
@Injectable()
export class VisaCounsellorAuthGuard implements CanActivate {
 
    constructor(
        private router: Router,
        public storage: Storage,
        public authService: AuthenticationService
    ) { }
 
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let authUser = this.authService.authUser;
        if (authUser) {
            if(authUser.type == 'visa-counsellor'){
                return true;
            }
        }
 
        // not logged in so redirect to login page with the return url
        this.router.navigate(['pages/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}