import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRoutes } from '../config/api-routes';
import 'rxjs/Rx';
import { Storage } from '../utilities/storage';
import { Location } from '../../../../node_modules/@angular/common';
import { Helper } from '../utilities/helpers';
import { AuthenticationService } from './authentication.service';

 
@Injectable()
export class ProgramService {

    public currentUser: any;
    public currentOwner: any;

    constructor(
        private http: HttpClient,
        private apiRoutes: ApiRoutes,
        public storage: Storage,
        public location: Location,
        public authService: AuthenticationService
    ) { 
        this.currentUser = this.authService.authUser;
        this.currentOwner = Helper.getObjProp(this.currentUser, 'owner');
     }
 
    getAllPrograms(params = {}) {
        let url = this.apiRoutes.getAllProgramsUrl(this.currentUser.id) + Helper.getQueryParamsString(params);
        return this.http.get(url)
        .map((res:any) => {
            return res;
        });
    }

    getAllProgramCategries() {
        let url = this.apiRoutes.getAllProgramCategoriesUrl(this.currentUser.id);
        return this.http.get(url)
        .map((res:any) => {
            return res.data;
        });
    }

    addProgram(form){
        let url = this.apiRoutes.getAddProgramUrl(this.currentUser.id);
        return this.http.post(url, form)
        .map((res: any) => {
            return res;
        });
    }

    updateProgram(programId, form){
        let url = this.apiRoutes.getUpdateProgramUrl(programId, this.currentUser.id);
        return this.http.put(url, form)
        .map((res: any) => {
            return res;
        });
    }

    getProgram(programId){
        let url = this.apiRoutes.getProgramByIdUrl(programId, this.currentUser.id);
        return this.http.get(url)
        .map((res: any) => {
            return res.data;
        });
    }
}