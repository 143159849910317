import { Component, TemplateRef, OnInit } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { Storage } from "../../../utilities/storage";
import { Toastr } from "../../../plugins/toastr/toastr";
import { UploadFileService } from "../../../services/upload-file.service";
import { AuthenticationService } from "../../../services/authentication.service";

@Component({
  selector: "upload-modal",
  templateUrl: "./upload.component.html",
  providers: [UploadFileService, Toastr],
})
export class UploadModal {
  image: any;
  selectedFiles: FileList;
  data: any;
  appositeUser: string;
  groupId: string;
  fileName: string = "";
  public loading: boolean = false;
  currentUser: any;
  authUser: any;

  constructor(
    public authService: AuthenticationService,
    private uploadService: UploadFileService,
    public toastr: Toastr,
    public modalRef: BsModalRef,
    public storage: Storage
  ) {
    this.setauthUser();
  }

  setauthUser() {
    this.currentUser = this.authService.authUser;
    this.authUser = this.currentUser.id;
  }

  ngOnInit() {}

  upload() {
    this._startLoading();
    if (this.selectedFiles == undefined) {
      this._stopLoading();
      this.toastr.showWarning(
        "You need to select a file in order to upload it."
      );
    } else {
      const file = this.selectedFiles.item(0);
      console.log("==================+>", file);
      let validateFile = this.validate_fileupload(file.name);
      if (validateFile) {
        let isOnline = navigator.onLine;
        console.log("=============+State+++++++++", isOnline);
        if (isOnline == false) {
          this.toastr.showError(
            "Seems like we are not able to reach our servers, please try again later."
          );
          this._stopLoading();
          return;
        }
        if (file.size > 10000000) {
          this.toastr.showWarning("File size should be less than 10 mb");
          this._stopLoading();
          return;
        }
        // console.log("==================<> Done");
        let dataObject = {
          authUser: this.authUser,
          appositeUser: this.appositeUser,
          groupId: this.groupId,
        };
        this.data = this.uploadService.uploadfile(file, dataObject);
        setTimeout(() => {
          this._stopLoading();
          this.toastr.showInfo("Document is uploading", "Information");
          this.modalRef.hide();
        }, 500);
      } else {
        this._stopLoading();
        this.toastr.showWarning("Selected file type is not allowed");
      }
    }
  }

  validate_fileupload(fileName) {
    let allowed_extensions = new Array(
      "jpg",
      "png",
      "pdf",
      "docx",
      "xlsx",
      "doc",
      "xls",
      "csv"
    );
    let file_extension = fileName.split(".").pop().toLowerCase(); // split function will split the filename by dot(.), and pop function will pop the last element from the array which will give you the extension as well. If there will be no extension then it will return the filename.

    for (let i = 0; i <= allowed_extensions.length; i++) {
      if (allowed_extensions[i] == file_extension) {
        return true; // valid file extension
      }
    }

    return false;
  }

  selectFile(event) {
    this.selectedFiles = event.target.files;
    this.fileName = event.target.files[0].name;
  }
  // fileEvent(fileInput: any) {
  //   const AWSService = AWS;
  //   const region = '<insert your region here>';
  //   const bucketName = '<insert your bucket name>';
  //   const IdentityPoolId = '<insert your identity pool id>';
  //   const file = fileInput.target.files[0];
  // //Configures the AWS service and initial authorization
  //   AWSService.config.update({
  //     region: region,
  //     credentials: new AWSService.CognitoIdentityCredentials({
  //       IdentityPoolId: IdentityPoolId
  //     })
  //   });
  // //adds the S3 service, make sure the api version and bucket are correct
  //   const s3 = new AWSService.S3({
  //     apiVersion: '2006-03-01',
  //     params: { Bucket: bucketName}
  //   });
  // //I store this in a variable for retrieval later
  //   this.image = file.name;
  //   s3.upload({ Key: file.name, Bucket: bucketName, Body: file, ACL: 'public-read'}, function (err, data) {
  //    if (err) {
  //      console.log(err, 'there was an error uploading your file');
  //    }
  //  });
  // }

  _startLoading() {
    this.loading = true;
  }

  _stopLoading() {
    this.loading = false;
  }
}
