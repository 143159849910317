import { Component, TemplateRef, OnInit, EventEmitter } from "@angular/core";
import { Validators, FormGroup, FormBuilder } from "@angular/forms";

import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { CollegeService } from "src/app/core/services/college.service";
import { Helper } from "src/app/core/utilities/helpers";
import { Toastr } from "src/app/core/plugins/toastr/toastr";
import { CustomValidator } from "src/app/core/utilities/validator/custom-validator";
import {AuthenticationService} from '../../../services/authentication.service'

@Component({
  selector: "edit-shortlisted-college-modal",
  templateUrl: "./edit-shortlisted-college.component.html",
  providers: [CollegeService],
})
export class EditShortlistedCollegeModal implements OnInit {
  studentId: any;
  collegeId: any;
  public currentUser: any;
  public items: any;

  public fields: any = [];

  public visibleFields: any = [];
  public loading: boolean = false;
  public metaData: any;
  public collegeDetails: any;
  public is_mentor: boolean = false;
  public commentsForm: FormGroup;
  public event: EventEmitter<any> = new EventEmitter();

  constructor(
    public modalRef: BsModalRef,
    public modalService: BsModalService,
    public collegeService: CollegeService,
    public authService: AuthenticationService,
    // public collegeProgramService: CollegeProgramService,
    private formBuilder: FormBuilder,

    public toastr: Toastr
  ) {
    this.currentUser = this.authService.authUser;
    this.fields = [
      { id: "1", fieldName: "Name", value: "name" },
      { id: "2", fieldName: "Alliance", value: "alliance" },
      { id: "3", fieldName: "Description", value: "description" },
      { id: "4", fieldName: "FT Ranking", value: "ft_ranking" },
      { id: "5", fieldName: "US News Ranking", value: "us_news_ranking" },
      { id: "6", fieldName: "State", value: "state" },
      { id: "7", fieldName: "Links", value: "links" },
      { id: "8", fieldName: "Duration", value: "duration" },
      { id: "9", fieldName: "Stem Designated", value: "stem_designated" },
      { id: "10", fieldName: "GMAT Avg", value: "gmat_avg" },
      { id: "11", fieldName: "GRE Minimum", value: "gre_minimum" },
      { id: "12", fieldName: "AWA", value: "awa" },
      { id: "13", fieldName: "SAT", value: "sat" },
      { id: "14", fieldName: "SAT Two", value: "sat_two" },
      { id: "15", fieldName: "Tution Fees", value: "tution_fees" },
      { id: "16", fieldName: "Spring Intake", value: "spring_intake" },
      { id: "17", fieldName: "UG Early Decision", value: "ug_early_decision" },
      { id: "18", fieldName: "UG Early Action", value: "ug_early_action" },
      {
        id: "19",
        fieldName: "UG Spring Deadline",
        value: "ug_spring_deadline",
      },
      { id: "20", fieldName: "MS Fall Deadline", value: "ms_fall_deadline" },
      {
        id: "21",
        fieldName: "MS Spring Deadline",
        value: "ms_spring_deadline",
      },
      { id: "22", fieldName: "MBA R1 Deadline", value: "mba_r1_deadline" },
      { id: "23", fieldName: "MBA R2 Deadline", value: "mba_r2_deadline" },
      { id: "24", fieldName: "MBA R3 Deadline", value: "mba_r3_deadline" },
      {
        id: "25",
        fieldName: "MBA Spring Deadline",
        value: "mba_spring_deadline",
      },
      { id: "26", fieldName: "Class Size", value: "class_size" },
      { id: "27", fieldName: "Acceptance Rate", value: "acceptance_rate" },
      { id: "28", fieldName: "Living Expenses", value: "living_expenses" },
      {
        id: "29",
        fieldName: "Scholarship Offering",
        value: "scholarship_offering",
      },
      { id: "30", fieldName: "Loan Options", value: "loan_options" },
      {
        id: "31",
        fieldName: "Fifteen Years Acceptability",
        value: "fifteen_years_acceptability",
      },
      { id: "32", fieldName: "GMAT Score Reporting Code", value: "gmat_score" },
      { id: "33", fieldName: "GRE Score Reporting Code", value: "gre_score" },
      { id: "34", fieldName: "SAT Score Reporting Code", value: "sat_score" },
      {
        id: "35",
        fieldName: "TOEFL Score Reporting Code",
        value: "toefl_score",
      },
      { id: "36", fieldName: "IELTS Score", value: "ielts_score" },
      { id: "37", fieldName: "Application Fees", value: "application_fees" },
      {
        id: "38",
        fieldName: "School Transcripts",
        value: "school_transcripts",
      },
      {
        id: "39",
        fieldName: "Bachelor Transcripts",
        value: "bachelor_transcripts",
      },
      {
        id: "40",
        fieldName: "Master Transcripts",
        value: "master_transcripts",
      },
      {
        id: "41",
        fieldName: "Semester Marksheets",
        value: "semester_marksheets",
      },
      {
        id: "42",
        fieldName: "Bachelor Degree Certificate",
        value: "bachelor_degree_certificate",
      },
      {
        id: "43",
        fieldName: "Master Degree Certificate",
        value: "master_degree_certificate",
      },
      { id: "44", fieldName: "WES Evaluation", value: "wes_evaluation" },
      {
        id: "45",
        fieldName: "UG Regular Deadline",
        value: "ug_regular_deadline",
      },
      { id: "46", fieldName: "TOEFL", value: "toefl" },
      {
        id: "47",
        fieldName: "PHD Spring Deadline",
        value: "phd_spring_deadline",
      },
      { id: "48", fieldName: "PHD Fall Deadline", value: "phd_fall_deadline" },
    ];
    this.items = [
      { id: 1, text: "Difficult" },
      { id: 2, text: "Less Difficult" },
      { id: 3, text: "Good Chance" },
      { id: 4, text: "GRE 260 - 265" },
      { id: 5, text: "GRE 265 - 270" },
      { id: 6, text: "GRE 270 - 275" },
      { id: 7, text: "GRE 275 - 280" },
      { id: 8, text: "GRE 280 - 285" },
      { id: 9, text: "GRE 285 - 290" },
      { id: 10, text: "GRE 290 - 295" },
      { id: 11, text: "GRE 295 - 300" },
      { id: 12, text: "GRE 300 - 305" },
      { id: 13, text: "GRE 305 - 310" },
      { id: 14, text: "GRE 310 - 315" },
      { id: 15, text: "GRE 315 - 320" },
      { id: 16, text: "GRE 320 - 325" },
      { id: 17, text: "GRE 325 - 330" },
      { id: 18, text: "GRE 330 - 335" },
      { id: 19, text: "GRE 335 - 340" },
      { id: 20, text: "GMAT 200 - 230" },
      { id: 21, text: "GMAT 230 - 260" },
      { id: 22, text: "GMAT 260 - 290" },
      { id: 23, text: "GMAT 290 - 320" },
      { id: 24, text: "GMAT 320 - 350" },
      { id: 25, text: "GMAT 350 - 380" },
      { id: 26, text: "GMAT 380 - 410" },
      { id: 27, text: "GMAT 410 - 440" },
      { id: 28, text: "GMAT 440 - 470" },
      { id: 29, text: "GMAT 470 - 500" },
      { id: 30, text: "GMAT 500 - 530" },
      { id: 31, text: "GMAT 530 - 560" },
      { id: 32, text: "GMAT 560 - 590" },
      { id: 33, text: "GMAT 590 - 620" },
      { id: 34, text: "GMAT 620 - 650" },
      { id: 35, text: "GMAT 650 - 680" },
      { id: 36, text: "GMAT 680 - 710" },
      { id: 37, text: "GMAT 710 - 740" },
      { id: 38, text: "GMAT 740 - 770" },
      { id: 39, text: "GMAT 770 - 800" },
      { id: 40, text: "SAT 400 - 450" },
      { id: 41, text: "SAT 450 - 500" },
      { id: 42, text: "SAT 500 - 550" },
      { id: 43, text: "SAT 550 - 600" },
      { id: 44, text: "SAT 600 - 650" },
      { id: 45, text: "SAT 650 - 700" },
      { id: 46, text: "SAT 700 - 750" },
      { id: 47, text: "SAT 750 - 800" },
      { id: 48, text: "SAT 800 - 850" },
      { id: 49, text: "SAT 850 - 900" },
      { id: 50, text: "SAT 900 - 950" },
      { id: 51, text: "SAT 950 - 1000" },
      { id: 52, text: "SAT 1000 - 1050" },
      { id: 53, text: "SAT 1050 - 1100" },
      { id: 54, text: "SAT 1100 - 1150" },
      { id: 55, text: "SAT 1150 - 1200" },
      { id: 56, text: "SAT 1200 - 1250" },
      { id: 57, text: "SAT 1250 - 1300" },
      { id: 58, text: "SAT 1300 - 1350" },
      { id: 59, text: "SAT 1350 - 1400" },
      { id: 60, text: "SAT 1400 - 1450" },
      { id: 61, text: "SAT 1450 - 1500" },
      { id: 62, text: "SAT 1500 - 1550" },
      { id: 63, text: "SAT 1550 - 1600" },
    ];
  }

  ngOnInit() {
    // this.is_mentor = window.location.href.includes("mentor");
    this._initForms();

    this._fetchCurrentFeildsFromServer();
    // this._initForms();
  }
  _initForms() {
    console.log("Helllllllo");
    this.commentsForm = this.formBuilder.group({
      selectionProability: ["", Validators.required],
      comments: ["", CustomValidator.lessThenLength(254)],
    });
  }
  _fetchCurrentFeildsFromServer(params = {}) {
    this._startLoading();
    params = Helper.cloneObj(params, {
      includes: "college_program,program_checklists,student,counsellors",
    });
    // if (this.is_mentor) {



    if(this.currentUser.type==='counsellor-manager'){
      this.collegeService
      .getCounsellingManagerStudentProgramDetails(
        this.studentId,
        this.collegeId,
        params
      )
      .subscribe(
        (res) => {
          console.log("=========College Details=========>", res);
          this.collegeDetails = res.data;

          console.log("================================", this.commentsForm);
          this._formatMetaData();

          this.fillingCounsellorComments(res.data);
        },
        (error) => {
          this._stopLoading();
          console.log(error);
        }
      );
    }else if(this.currentUser.type==='counsellor'){
      this.collegeService
      .getCounsellorStudentProgramDetails(
        this.studentId,
        this.collegeId,
        params
      )
      .subscribe(
        (res) => {
          console.log("=========College Details=========>", res);
          this.collegeDetails = res.data;

          console.log("================================", this.commentsForm);
          this._formatMetaData();

          this.fillingCounsellorComments(res.data);
        },
        (error) => {
          this._stopLoading();
          console.log(error);
        }
      );
    }
    


    // } else {
    //   this.collegeService
    //     .getCounsellorStudentProgramDetails(
    //       this.studentId,
    //       this.collegeId,
    //       params
    //     )
    //     .subscribe(
    //       (res) => {
    //         console.log("=========College Details=========>", res);
    //         this.collegeDetails = res.data;
    //         this._formatMetaData();
    //       },
    //       (error) => {
    //         this._stopLoading();
    //         console.log(error);
    //       }
    //     );
    // }
  }
  fillingCounsellorComments(data) {
    console.log("=========+>", data);
    this.commentsForm.controls["selectionProability"].setValue(
      data.selection_probability
    );
    this.commentsForm.controls["comments"].setValue(data.counsellor_comments);

    // this.commentsForm = this.formBuilder.group({
    //   selectionProability: [data.selection_probability, Validators.required],
    //   comments: [data.counsellor_comments, CustomValidator.lessThenLength(254)],
    // });
    console.log("jail");
  }
  checkAll() {
    let tempArray = [];
    this.fields.forEach((element) => {
      tempArray.push(element.value);
    });
    // console.log(this.visibleFields, tempArray);

    let arrayDiff: any = [];
    arrayDiff = Helper.arrayDifference(tempArray, this.visibleFields);
    // console.log("arrayDiff:::", arrayDiff);
    if (arrayDiff.length == 0) {
      return true;
    } else {
      return false;
    }
  }
  selectAllFields(event) {
    if (event.target.checked) {
      this.visibleFields = [];
      this.fields.forEach((element) => {
        this.visibleFields.push(element.value);
      });
    } else {
      this.visibleFields = [];
    }

    // console.log(this.visibleFields);
  }
  _formatMetaData() {
    let metaData = Helper.getObjProp(
      this.collegeDetails,
      "college_program.meta_data"
    );
    const arr = [];
    Object.keys(metaData).map((data) => {
      if (data == "type" || data == "type_text") {
        //do nothing
      } else {
        arr.push(data);
      }
    });
    console.log("==========><", this.visibleFields);
    this.visibleFields = arr;
    this._stopLoading();
  }

  checkFields(event) {
    if (event.target.checked) {
      this.visibleFields.push(event.target.value);
    } else {
      let tempShortlistedColleges = this.visibleFields;
      this.visibleFields = [];
      tempShortlistedColleges.forEach((element) => {
        if (element == event.target.value) {
          // Do Nothing
        } else {
          this.visibleFields.push(element);
        }
      });
    }

    // console.log(this.visibleFields);
  }

  // submit() {
  //   this._startLoading();
  //   let tempObject = {
  //     visible_fields: this.visibleFields,
  //   };
  //   if (this.is_mentor) {
  //     this.collegeProgramService
  //       .updateShortlistedCollegeProgramByMentor(
  //         this.studentId,
  //         this.collegeId,
  //         tempObject
  //       )
  //       .subscribe(
  //         (res) => {
  //           this.toastr.showSuccess("Successfully updated the fields");
  //           this.modalRef.hide();
  //           this._stopLoading();
  //         },
  //         (error) => {
  //           this._stopLoading();
  //         }
  //       );
  //   } else {
  //     this.collegeProgramService
  //       .updateShortlistedCollegeProgram(
  //         this.studentId,
  //         this.collegeId,
  //         tempObject
  //       )
  //       .subscribe(
  //         (res) => {
  //           this.toastr.showSuccess("Successfully updated the fields");
  //           this.modalRef.hide();
  //           this._stopLoading();
  //         },
  //         (error) => {
  //           this._stopLoading();
  //         }
  //       );
  //   }
  // }

  triggerFailEvent() {
    this.event.emit({ success: false });
    this.modalRef.hide();
  }

  triggerSuccessEvent() {
    let tempObject = Helper.cloneObj(
      {
        success: true,
        visible_fields: this.visibleFields,
      },
      this.commentsForm.value
    );
    console.log("Successfully Done", tempObject);
    console.log(this.commentsForm, this.modalRef);

    // this.modalRef.hide();

    // this.collegeService
    //   .updateStudentProgramDetailsByCounsellingManager(
    //     this.studentId,
    //     this.collegeId,
    //     tempObject
    //   )
    //   .subscribe(
    //     (res) => {
    //       console.log("=========College Details=========>", res);
    //       this.collegeDetails = res.data;
    //       this.toastr.showSuccess("Successfully updated college for student.");
    //       this._formatMetaData();
    //       // console.log("================================", this.commentsForm);
    //       // this.fillingCounsellorComments(res.data);
    //     },
    //     (error) => {
    //       this._stopLoading();
    //       console.log(error);
    //     }
    //   );

    this.event.emit(tempObject);
    this.modalRef.hide();
  }
  _startLoading() {
    this.loading = true;
  }

  _stopLoading() {
    this.loading = false;
  }
}
