import { Component, TemplateRef, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ActivatedRoute } from '../../../../../../node_modules/@angular/router';
import { UserService } from '../../../services/user.services';
import { Toastr } from '../../../plugins/toastr/toastr';
import { Helper } from '../../../utilities/helpers';
import { NgxPaginationModule } from 'ngx-pagination';

@Component({
	selector: 'change-counsellor-modal',
	templateUrl: './change-counsellor.component.html',
	providers: [
		UserService,
	]
})
export class ChangeCounsellorModal {

	counsellors: any = [];
	public selectedCounsellor: any = '';
	public studentId: any;
	public per_page: number = 0;
	public page: number = 0;
	public total: number = 0;

	constructor(
		public modalRef: BsModalRef,
		private route: ActivatedRoute,
		public userService: UserService,
		public toastr: Toastr
	) {
		this.getAllCounsellorsForPage();
		this.counsellors = [];
	}

	getAllCounsellorsForPage(page: number = 1, params = {}) {
		params = Helper.cloneObj(params, {
			page: page,
		})
		this.userService.getAllCounsellors(params).subscribe(
			res => {
				console.log('=-=-=-=-=-=-=-=-=-=--=-=');
				let paginationObj = Helper.getObjProp(res, 'meta.pagination');
				this.per_page = paginationObj.per_page;
				this.page = paginationObj.current_page;
				this.total = paginationObj.total;
				
				this.counsellors = res.data;
				// let index = 0;
				// res.data.forEach(element => {
				// 	index = index + 1;
				// 	let tempObject = {
				// 		id: index,
				// 		userId: element.id,
				// 		name: element.full_name
				// 	};

				// 	this.counsellors.push(tempObject);
				// });
			},
			error => {
				console.log(error);
			});
	}


	check(e) {
		this.selectedCounsellor = e.target.value;
		console.log(this.selectedCounsellor);
	}

	submitCounsellor() {
		if (this.selectedCounsellor == '') {
			this.toastr.showWarning('You need to select atleast one.');
			return;
		}
		this.userService.associateStudentCounsellor(this.studentId, this.selectedCounsellor).subscribe(
			res => {
				this.toastr.showSuccess('Successfully assigned counselling manager to student');
				this.modalRef.hide();
			},
			error => {
				console.log(error);
			});
	}

}