import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiRoutes } from "../config/api-routes";
import "rxjs/Rx";
import { Router, ActivatedRoute } from "@angular/router";
import { Storage } from "../utilities/storage";
import { Helper } from "../utilities/helpers";

@Injectable()
export class StudentService {
  public collegeDetails: any = null;
  public currentUser: any;
  public currentOwner: any;
  public studentName: any;

  constructor(
    private http: HttpClient,
    private apiRoutes: ApiRoutes,
    private router: Router,
    public storage: Storage
  ) {
    this.currentUser = this.storage.get("authUser");
    this.currentOwner = this.storage.get("currentOwner");
  }

  getStudentDetails(studentId, params: Object = {}) {
    let url =
      this.apiRoutes.getStudentDetailsUrl(this.currentUser.id, studentId) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res.data;
    });
  }

  getCounsellorStudentDetails(studentId, params: Object = {}) {
    let url =
      this.apiRoutes.getCounsellorStudentDetailsUrl(
        this.currentUser.id,
        studentId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res.data;
    });
  }

  removeEnrollmentFormHighlights(studentId) {
    let url =
      this.apiRoutes.removeEnrollmentFormHighlightsUrl(
        this.currentUser.id,
        studentId
      ) 
      return this.http.delete(url);
  }

  getMentorStudentDetails(studentId, params: Object = {}) {
    let url =
      this.apiRoutes.getMentorStudentDetailsUrl(
        this.currentUser.id,
        studentId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res.data;
    });
  }

  getVisaCounsellorStudentDetails(studentId, params: Object = {}) {
    let url =
      this.apiRoutes.getVisaCounsellorStudentDetailsUrl(
        this.currentUser.id,
        studentId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res.data;
    });
  }

  getInterviewTrainerStudentDetails(studentId, params: Object = {}) {
    let url =
      this.apiRoutes.getInterviewTrainerStudentDetailsUrl(
        this.currentUser.id,
        studentId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res.data;
    });
  }

  getCounsellingManagerStudentDetails(studentId, params: Object = {}) {
    let url =
      this.apiRoutes.getCounsellingManagerStudentDetailsUrl(
        this.currentUser.id,
        studentId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res.data;
    });
  }

  getVisaCounsellingManagerStudentDetails(studentId, params: Object = {}) {
    let url =
      this.apiRoutes.getVisaCounsellingManagerStudentDetailsUrl(
        this.currentUser.id,
        studentId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res.data;
    });
  }

  getInterviewTrainerManagerStudentDetails(studentId, params: Object = {}) {
    let url =
      this.apiRoutes.getInterviewTrainerManagerStudentDetailsUrl(
        this.currentUser.id,
        studentId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res.data;
    });
  }

  getWriterManagerStudentDetails(studentId, params: Object = {}) {
    let url =
      this.apiRoutes.getWriterManagerStudentDetailsUrl(
        this.currentUser.id,
        studentId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res.data;
    });
  }

  getWriterStudentDetails(studentId, params: Object = {}) {
    let url =
      this.apiRoutes.getWriterStudentDetailsUrl(
        this.currentUser.id,
        studentId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res.data;
    });
  }

  updateCounsellorStudentHistory(studentId, actionId, formData) {
    let url = this.apiRoutes.getUpdateCounsellorStudentHistoryUrl(
      this.currentUser.id,
      studentId,
      actionId
    );
    return this.http.put(url, formData).map((res: any) => {
      return res.data;
    });
  }

  updateWriterStudentHistory(studentId, actionId, formData) {
    let url = this.apiRoutes.getUpdateWriterStudentHistoryUrl(
      this.currentUser.id,
      studentId,
      actionId
    );
    return this.http.put(url, formData).map((res: any) => {
      return res.data;
    });
  }

  updateMentorStudentHistory(studentId, actionId, formData) {
    let url = this.apiRoutes.getUpdateMentorStudentHistoryUrl(
      this.currentUser.id,
      studentId,
      actionId
    );
    return this.http.put(url, formData).map((res: any) => {
      return res.data;
    });
  }

  getCounsellorStudentRoadmap(studentId, params = {}) {
    let url =
      this.apiRoutes.getCounsellorStudentRoadmapUrl(
        this.currentUser.slug,
        studentId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }
  getAdminStudentRoadmap(studentId, params = {}) {
    let url =
      this.apiRoutes.getAdmintudentRoadmapUrl(
        this.currentUser.slug,
        studentId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  addCounsellorStudentRoadmap(studentId, formData) {
    return this.http
      .post(
        this.apiRoutes.getCounsellorStudentRoadmapUrl(
          this.currentUser.slug,
          studentId
        ),
        formData
      )
      .map((res: any) => {
        return res;
      });
  }

  // deleteCounsellorStudentRoadmap(studentId, taskId){
  //     return this.http.delete(this.apiRoutes.getUpdateCounsellorStudentRoadmapUrl(this.currentUser.slug, studentId, taskId))
  //     .map((res:any) => {
  //         return res;
  //     });
  // }

  updateCounsellorStudentCollegeResult(studentId, collegeId, formData) {
    return this.http
      .put(
        this.apiRoutes.getUpdateCounsellorStudentCollegeResult(
          this.currentUser.slug,
          studentId,
          collegeId
        ),
        formData
      )
      .map((res: any) => {
        return res;
      });
  }

  updateMentorStudentCollegeResult(studentId, collegeId, formData) {
    return this.http
      .put(
        this.apiRoutes.getUpdateMentorStudentCollegeResult(
          this.currentUser.slug,
          studentId,
          collegeId
        ),
        formData
      )
      .map((res: any) => {
        return res;
      });
  }

  // put enrollment details from counsellor side
  putEnrollmentDetailCounsellor(editedEnrollmentData, studentSlug) {
    let url = this.apiRoutes.putEnrollmentDetailsCounsellor(
      this.currentUser.id,
      studentSlug
    );
    return this.http.put(url, editedEnrollmentData);
  }

  // put enrollment details from admin side
  putEnrollmentDetailAdmin(editedEnrollmentData, studentSlug) {
    let url = this.apiRoutes.putEnrollmentDetailsAdmin(
      this.currentUser.id,
      studentSlug
    );
    return this.http.put(url, editedEnrollmentData);
  }

  // Mentor's Individual student details
  getMentorIndividualStudentDetail(studentSlug) {
    let url = this.apiRoutes.getMentorIndividualStudentDetail(
      this.currentUser.id,
      studentSlug
    );
    return this.http.get(url).map((res) => {
      return res;
    });
  }

  // Mentor's roadmap of student
  getMentorStudentRoadmap(studentId, params = {}) {
    let url =
      this.apiRoutes.getMentorStudentRoadmap(this.currentUser.slug, studentId) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res) => {
      return res;
    });
  }

  // Add student roadmap from mentor side
  getAddMentorStudentRoadmap(studentId, dataRoadmap) {
    let url = this.apiRoutes.getAddMentorStudentRoadmap(
      this.currentUser.slug,
      studentId
    );
    return this.http.post(url, dataRoadmap);
  }

  // edit roadmap for counsellor and mentor
  getUpdateRoadmapCounsellor(studentId, taskId, dataRoadmap) {
    let url = this.apiRoutes.getUpdateRoadmapCounsellorUrl(
      this.currentUser.id,
      studentId,
      taskId
    );
    return this.http.put(url, dataRoadmap);
  }
  // delete roadmap for counsellor
  deleteRoadmapByCounsellor(studentId, taskId) {
    let url = this.apiRoutes.getDeleteRoadmapCounsellorUrl(
      this.currentUser.id,
      studentId,
      taskId
    );
    return this.http.delete(url);
  }
  getUpdateRoadmapMentor(studentId, taskId, dataRoadmap) {
    let url = this.apiRoutes.getUpdateRoadmapMentorUrl(
      this.currentUser.id,
      studentId,
      taskId
    );
    return this.http.put(url, dataRoadmap);
  }
  // delete roadmap for mentor
  deleteRoadmapByMentor(studentId, taskId) {
    let url = this.apiRoutes.getDeleteRoadmapMentorUrl(
      this.currentUser.id,
      studentId,
      taskId
    );
    return this.http.delete(url);
  }

  deleteTaskByCounsellor(studentId, taskId) {
    let url = this.apiRoutes.deleteTaskUrl(
      this.currentUser.id,
      studentId,
      taskId
    );
    return this.http.delete(url);
  }

  getRoadmapTags() {
    return this.http.get(this.apiRoutes.getRoadmapTagsUrl()).map((res: any) => {
      return res;
    });
  }

  //===========================================================
  // Activity Apis Mentor
  //===========================================================
  // student owner id pass
  getAddActivityMentor(studentId, tagId, data) {
    return this.http
      .post(
        this.apiRoutes.getAddActivityMentorUrl(
          this.currentUser.slug,
          studentId,
          tagId
        ),
        data
      )
      .map((res: any) => {
        return res;
      });
  }
  getUpdateActivityMentor(studentId, activityId, data) {
    return this.http
      .put(
        this.apiRoutes.getUpdateActivityMentorUrl(
          this.currentUser.slug,
          studentId,
          activityId
        ),
        data
      )
      .map((res: any) => {
        return res;
      });
  }
  getActivityMentor(studentId) {
    return this.http
      .get(
        this.apiRoutes.getActivityMentorUrl(this.currentUser.slug, studentId)
      )
      .map((res: any) => {
        return res;
      });
  }

  //=============================================================
  // Activity Apis Counsellor
  //=============================================================
  getAddActivityCounsellor(studentId, tagId, data) {
    return this.http
      .post(
        this.apiRoutes.getAddActivityCounsellorUrl(
          this.currentUser.slug,
          studentId,
          tagId
        ),
        data
      )
      .map((res: any) => {
        return res;
      });
  }
  getUpdateActivityCounsellor(studentId, activityId, data) {
    return this.http
      .put(
        this.apiRoutes.getUpdateActivityCounsellorUrl(
          this.currentUser.slug,
          studentId,
          activityId
        ),
        data
      )
      .map((res: any) => {
        return res;
      });
  }
  getActivityCounsellor(studentId) {
    return this.http
      .get(
        this.apiRoutes.getActivityCounsellorUrl(
          this.currentUser.slug,
          studentId
        )
      )
      .map((res: any) => {
        return res;
      });
  }

  //=============================================================
  // Activity Api Admin
  //=============================================================
  getActivityAdmin(studentslug) {
    return this.http
      .get(
        this.apiRoutes.getActivityAdminUrl(this.currentUser.slug, studentslug)
      )
      .map((res: any) => {
        return res;
      });
  }

  //=============================================================
  // Appointment Service
  //=============================================================
  getCreateAppointmentByMentor(appointmentData) {
    return this.http
      .post(
        this.apiRoutes.getCreateAppointmentByMentorUrl(this.currentUser.id),
        appointmentData
      )
      .map((res: any) => {
        return res;
      });
  }

  getCreateAppointmentByCounsellor(appointmentData) {
    return this.http
      .post(
        this.apiRoutes.getCreateAppointmentByCounsellorUrl(this.currentUser.id),
        appointmentData
      )
      .map((res: any) => {
        return res;
      });
  }

  getEditAppointmentMentorSlot(appointmentId, data) {
    let url = this.apiRoutes.getConfirmAppointmentMentorSlot(
      appointmentId,
      this.currentUser.id
    );
    return this.http.patch(url, data).map((res: any) => {
      return res;
    });
  }

  getEditAppointmentCounsellorSlot(appointmentId, data) {
    let url = this.apiRoutes.getConfirmAppointmentCounsellorSlot(
      appointmentId,
      this.currentUser.id
    );
    return this.http.patch(url, data).map((res: any) => {
      return res;
    });
  }
}
