import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiRoutes } from "../config/api-routes";
import "rxjs/Rx";
import { Storage } from "../utilities/storage";
import { Helper } from "../utilities/helpers";
import { AuthenticationService } from "./authentication.service";

@Injectable()
export class CollegeProgramService {
  public currentUser: any;

  constructor(
    private http: HttpClient,
    private apiRoutes: ApiRoutes,
    public storage: Storage,
    public authService: AuthenticationService
  ) {
    this.currentUser = this.authService.authUser;
  }
  addCollegeProgram(form) {
    console.log("comes under api");
    let url = this.apiRoutes.getAddCollegeProgramUrl(this.currentUser.id);
    return this.http.post(url, form).map((res: any) => {
      console.log("response for file upload", res);
      return res;
    });
  }
  getCollegeProgram(collegeProgramId, params = {}) {
    let url =
      this.apiRoutes.getCollegeProgramUrl(
        collegeProgramId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res.data;
    });
  }
  getCounsellorCollegeProgram(collegeProgramId, params = {}) {
    let url =
      this.apiRoutes.getCounsellorCollegeProgramUrl(
        collegeProgramId,
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res.data;
    });
  }

  getCounsellingManagerCounsellorProgram(collegeId, params = {}) {
    return this.http
      .get(
        this.apiRoutes.getCounsellingManagerCounsellorProgramIndividualUrl(
          this.currentUser.id,
          collegeId
        ) + Helper.getQueryParamsString(params)
      )
      .map((res: any) => {
        return res.data;
      });
  }

  updateCollegeProgram(collegeProgramId, form) {
    let url = this.apiRoutes.getCollegeProgramUrl(
      collegeProgramId,
      this.currentUser.id
    );
    return this.http.put(url, form).map((res: any) => {
      return res;
    });
  }
  updateCounsellorCollegeProgram(collegeProgramId, form) {
    let url = this.apiRoutes.getCounsellorCollegeProgramUrl(
      collegeProgramId,
      this.currentUser.id
    );
    return this.http.put(url, form).map((res: any) => {
      return res;
    });
  }

  updateUpcomingChangesColumn(collegeProgramId, form){
    let url = this.apiRoutes.updateUpcomingChangesColumnUrl(
      collegeProgramId,
      this.currentUser.id
    );
    return this.http.patch(url,form).map((res:any) => {
      return res;
    })
  }

  approveUpcomingChangesColumn(collegeProgramId, form){
    let url = this.apiRoutes.ApproveUpcomingChangesColumnUrl(
      collegeProgramId,
      this.currentUser.id
    );
    return this.http.patch(url,form).map((res:any) => {
      return res;
    })
  }

  UnapproveUpcomingChanges(collegeProgramId){
    let url = this.apiRoutes.UnapproveUpcomingChangesUrl(
      collegeProgramId,
      this.currentUser.id
    );
    return this.http.delete(url).map(( res: any) => {
      return res;
    })
  }

  addCollegeProgramDocuments(collegeProgramId, form) {
    let url = this.apiRoutes.getAddCollegeProgramDocumentUrl(
      collegeProgramId,
      this.currentUser.id
    );
    return this.http.post(url, form).map((res: any) => {
      return res;
    });
  }
  addCollegeProgramChecklists(collegeProgramId, form) {
    let url = this.apiRoutes.getAddCollegeProgramChecklistUrl(
      collegeProgramId,
      this.currentUser.id
    );
    return this.http.post(url, form).map((res: any) => {
      return res;
    });
  }
  removeDocument(collegeProgramId, documentId) {
    let url = this.apiRoutes.getCollegeProgramDocumentUrl(
      collegeProgramId,
      documentId,
      this.currentUser.id
    );
    return this.http.delete(url).map((res: any) => {
      return res;
    });
  }

  enableDocument(collegeProgramId, documentId) {
    let url = this.apiRoutes.getCollegeProgramDocumentUrl(
      collegeProgramId,
      documentId,
      this.currentUser.id
    );
    return this.http.put(url, { is_enabled: 1 }).map((res: any) => {
      return res;
    });
  }

  disableDocument(collegeProgramId, documentId) {
    let url = this.apiRoutes.getCollegeProgramDocumentUrl(
      collegeProgramId,
      documentId,
      this.currentUser.id
    );
    return this.http.put(url, { is_enabled: 0 }).map((res: any) => {
      return res;
    });
  }

  removeTask(collegeProgramId, taskId) {
    let url = this.apiRoutes.getDeleteCollegeProgramTaskUrl(
      collegeProgramId,
      taskId,
      this.currentUser.id
    );
    return this.http.delete(url).map((res: any) => {
      return res;
    });
  }
  getUserColleges(params = {}) {
    let url =
      this.apiRoutes.getUserProgrammesUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getUnassignedUserColleges(params = {}) {
    let url =
      this.apiRoutes.getUnassignedUserProgrammesUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getCounsellingManagerColleges(params = {}) {
    let url =
      this.apiRoutes.getCounsellingManagerProgrammesUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }
  getCounsellorColleges(params = {}) {
    let url =
      this.apiRoutes.getCounsellorCollegeProgrammesUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }
  getCounsellorCollegesForStudent(studentId, params = {}) {
    let url =
      this.apiRoutes.getCounsellorCollegeProgrammesForStudentUrl(
        this.currentUser.id,
        studentId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }
  getCounsellorManagerCollegePrograms(studentId, params = {}) {
    let url =
      this.apiRoutes.getCounsellorManagerCollegePrograms(
        this.currentUser.id
        // studentId
      ) + Helper.getQueryParamsString(params);
    console.log("=========><", url);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getMentorCollegesForStudent(studentId, params = {}) {
    let url =
      this.apiRoutes.getMentorCollegeProgrammesForStudentUrl(
        this.currentUser.id,
        studentId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  addCounsellorColleges(counsellorId, form) {
    let url = this.apiRoutes.getManagerCounsellorCollegesUrl(
      this.currentUser.id,
      counsellorId
    );
    return this.http.post(url, form).map((res: any) => {
      return res;
    });
  }

  addMentorColleges(mentorId, form) {
    let url = this.apiRoutes.getManagerMentorCollegesUrl(
      this.currentUser.id,
      mentorId
    );
    return this.http.post(url, form).map((res: any) => {
      return res;
    });
  }
  addCounsellingManagerCounsellorColleges(counsellorId, form) {
    let url = this.apiRoutes.getCounsellingManagerCounsellorCollegesUrl(
      this.currentUser.id,
      counsellorId
    );
    return this.http.post(url, form).map((res: any) => {
      return res;
    });
  }
  removeStudentSelfShortlistedCollegeProgram(studentId, collegeProgramId) {
    let url = this.apiRoutes.getRemoveStudentSelfShortlistedCollegeProgramUrl(
      this.currentUser.id,
      studentId,
      collegeProgramId
    );
    return this.http.delete(url).map((res: any) => {
      return res;
    });
  }
  shortListCollegesForStudent(studentId, localShortlistedColleges) {
    let url = this.apiRoutes.getShortListCollegesForStudentUrl(
      this.currentUser.id,
      studentId
    );
    return this.http.post(url, localShortlistedColleges).map((res: any) => {
      return res;
    });
  }
  shortListCollegesPostByCounsellorManager(
    studentId,
    localShortlistedColleges
  ) {
    let url = this.apiRoutes.getShortListCollegesPostByCounsellorManagerUrl(
      this.currentUser.id,
      studentId
    );
    return this.http.post(url, localShortlistedColleges).map((res: any) => {
      return res;
    });
  }

  shortListCollegesForStudentByMentor(studentId, localShortlistedColleges) {
    let url = this.apiRoutes.getShortListCollegesForStudentByMentorUrl(
      this.currentUser.id,
      studentId
    );
    return this.http.post(url, localShortlistedColleges).map((res: any) => {
      return res;
    });
  }

  updateShortlistedCollegeProgram(studentId, collegeProgramId, formData) {
    let url = this.apiRoutes.getUpdateShortlistedCollegeProgramUrl(
      this.currentUser.id,
      studentId,
      collegeProgramId
    );
    return this.http.put(url, formData).map((res: any) => {
      return res;
    });
  }

  updateShortlistedCollegeProgramByMentor(
    studentId,
    collegeProgramId,
    formData
  ) {
    let url = this.apiRoutes.getUpdateShortlistedCollegeProgramByMentorUrl(
      this.currentUser.id,
      studentId,
      collegeProgramId
    );
    return this.http.put(url, formData).map((res: any) => {
      return res;
    });
  }

  deassignCounsellingManagerCounsellorCollegeProgram(
    counsellorId,
    collegeProgramId
  ) {
    let url =
      this.apiRoutes.getDeassignCounsellingManagerCounsellorCollegeProgramUrl(
        this.currentUser.id,
        counsellorId,
        collegeProgramId
      );
    return this.http.delete(url).map((res: any) => {
      return res;
    });
  }
  deassignManagerCounsellorCollegeProgram(counsellorId, collegeProgramId) {
    let url = this.apiRoutes.getDeassignManagerCounsellorCollegeProgramUrl(
      this.currentUser.id,
      counsellorId,
      collegeProgramId
    );
    return this.http.delete(url).map((res: any) => {
      return res;
    });
  }

  deassignManagerMentorCollegeProgram(mentorId, collegeProgramId) {
    let url = this.apiRoutes.getDeassignManagerMentorCollegeProgramUrl(
      this.currentUser.id,
      mentorId,
      collegeProgramId
    );
    return this.http.delete(url).map((res: any) => {
      return res;
    });
  }
  unMarkDraft(draftId, studentId, collegeId, documentId) {
    let url = this.apiRoutes.getMarkUnmarkUrl(
      this.currentUser.id,
      studentId,
      collegeId,
      documentId,
      draftId
    );
    return this.http.patch(url, {}).map((res: any) => {
      return res;
    });
  }
  getStudentShortlistedCollegeProgramInterviewSessions(
    studentId,
    interviewId,
    params = {}
  ) {
    let url =
      this.apiRoutes.getStudentShortlistedCollegeProgramInterviewSessionsUrl(
        this.currentUser.id,
        studentId,
        interviewId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }
  getStudentShortlistedCollegeProgramVisaSessions(
    studentId,
    interviewId,
    params = {}
  ) {
    let url =
      this.apiRoutes.getStudentShortlistedCollegeProgramVisaSessionsUrl(
        this.currentUser.id,
        studentId,
        interviewId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }
  getStudentShortlistedCollegeProgramInterviewSessionsForCounsellor(
    studentId,
    interviewId,
    params = {}
  ) {
    let url =
      this.apiRoutes.getStudentShortlistedCollegeProgramInterviewSessionsForCounsellorUrl(
        this.currentUser.id,
        studentId,
        interviewId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getStudentShortlistedCollegeProgramInterviewSessionsForMentor(
    studentId,
    interviewId,
    params = {}
  ) {
    let url =
      this.apiRoutes.getStudentShortlistedCollegeProgramInterviewSessionsForMentorUrl(
        this.currentUser.id,
        studentId,
        interviewId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getStudentShortlistedCollegeProgramInterviewSessionsForCounsellorManager(
    studentId,
    interviewId,
    params = {}
  ) {
    let url =
      this.apiRoutes.getStudentShortlistedCollegeProgramInterviewSessionsForCounsellorManagerUrl(
        this.currentUser.id,
        studentId,
        interviewId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }
  getStudentShortlistedCollegeProgramVisaSessionsForCounsellor(
    studentId,
    interviewId,
    params = {}
  ) {
    let url =
      this.apiRoutes.getStudentShortlistedCollegeProgramVisaSessionsForCounsellorUrl(
        this.currentUser.id,
        studentId,
        interviewId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getStudentShortlistedCollegeProgramVisaSessionsForMentor(
    studentId,
    interviewId,
    params = {}
  ) {
    let url =
      this.apiRoutes.getStudentShortlistedCollegeProgramVisaSessionsForMentorUrl(
        this.currentUser.id,
        studentId,
        interviewId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getStudentShortlistedCollegeProgramVisaSessionsForCounsellorManager(
    studentId,
    interviewId,
    params = {}
  ) {
    let url =
      this.apiRoutes.getStudentShortlistedCollegeProgramVisaSessionsForCounsellorManagerUrl(
        this.currentUser.id,
        studentId,
        interviewId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }
  getInterviewTrainerStudentShortlistedCollegeProgramInterviewSessions(
    studentId,
    interviewId,
    params = {}
  ) {
    let url =
      this.apiRoutes.getInterviewTrainerStudentShortlistedCollegeProgramInterviewSessionsUrl(
        this.currentUser.id,
        studentId,
        interviewId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }
  getVisaCounsellorStudentShortlistedCollegeProgramVisaSessions(
    studentId,
    visaId,
    params = {}
  ) {
    let url =
      this.apiRoutes.getVisaCounsellorStudentShortlistedCollegeProgramVisaSessionsUrl(
        this.currentUser.id,
        studentId,
        visaId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getVisaCounsellorStudentVisaSessions(studentId, visaId, params = {}) {
    let url =
      this.apiRoutes.getVisaCounsellorStudentVisaSessionsUrl(
        this.currentUser.id,
        studentId,
        visaId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getAllCollegeProgram(params={}){
    let url = this.apiRoutes.getAllCollegePrograms(
      this.currentUser.id
    ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res:any)=> {
      return res;
    })
  }

  //=====================================================
  // Copy Drafts
  //=====================================================

  copyDraft(formData, studentId, collegeId, documentId) {
    let url = this.apiRoutes.getCopyDraftUrl(
      this.currentUser.id,
      studentId,
      collegeId,
      documentId
    );
    return this.http.post(url, formData).map((res: any) => {
      return res;
    });
  }
}
