import { Injectable, Component } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Storage } from '../utilities/storage';
import { Toastr } from '../plugins/toastr/toastr';
import { AuthenticationService } from './authentication.service';
import { Router } from '../../../../node_modules/@angular/router';
import { Helper } from '../utilities/helpers';
import settings from '../config/settings';

@Injectable()
export class JwtInterceptorService implements HttpInterceptor {

    constructor(
        public storage: Storage,
        public toastr: Toastr,
        public auth: AuthenticationService,
        private router: Router
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let authUser = this.storage.get('authUser');
        if (authUser && authUser.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${authUser.token}`
                }
            });
        }

        return next.handle(request).map((event: HttpEvent<any>) => {
            return event;
        })
            .catch((err: any, caught) => {
                if (err instanceof HttpErrorResponse) {


                    switch (err.status) {
                        case 0:
                            this.toastr.showError('Oops something went wrong');
                            console.log('===================+>Error Page Executed');
                            // this.router.navigate(['error']);
                            break;

                        case 401:
                            this.toastr.showInfo('You just need to sign in once again', 'Dont Worry');
                            this.auth.logout().subscribe(
                                res =>{
                                  if(res){
                                    this.router.navigate(['/pages/login']);
                                  }
                                }
                              );
                            break;

                        // case 500:
                        //     this.toastr.showError('Oops Something Went Wrong');
                        //     break;

                        default:
                            Helper.processErrorResponse(err, this.toastr);
                            break;
                    }
                    return Observable.throw(err);
                }
            });
    }
}