import {Component, TemplateRef, OnInit, ViewChild} from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Storage } from '../../../utilities/storage';
import { Toastr } from '../../../plugins/toastr/toastr';
import { ApiRoutes } from '../../../config/api-routes';
import { Helper } from '../../../utilities/helpers';
import settings from '../../../config/settings';
import { AuthenticationService } from '../../../services/authentication.service';
@Component({
  selector: 'upload-draft-modal',
  templateUrl: './upload-draft.component.html',
  providers: [ Toastr ]
})
export class UploadDraftModal {

  @ViewChild('uploaderInput') mediaUploaderInput: any;

  public loading: boolean = false;
  public uploader: FileUploader;
  public authUser: any;
  public currentOwner: any;
	public fileName: string = '';
	public submitted: boolean = false;
  public uploadValidation: boolean = false;
  collegeId: any;
	documentId: any;
	studentId: any;
 
  constructor(
    public toastr: Toastr, 
		public apiConfig: ApiRoutes,
    public modalRef: BsModalRef, 
    public storage: Storage,
		public authService: AuthenticationService
    ) {
  }
 
  ngOnInit() {
    this.getCurrentUser();
    this.initFileUploader();
  }

  getCurrentUser() {
		this.authUser = this.storage.get('authUser');
	}

  initFileUploader() {
		this.uploader = new FileUploader({
			url: this.apiConfig.getPostDocumentDraftsUrl(this.authUser.id, this.studentId,this.collegeId, this.documentId),
			itemAlias:"draft",
			method: 'POST',
			authTokenHeader: "Authorization",
      		authToken: this.authService.getAuthToken
		});

		this.uploader.onAfterAddingFile = (file) => {
			if(file.file.size > 1000000){
				this.toastr.showWarning('File size should be less than 1 Mb');
				this.uploadValidation = true;
			}else{
				let validateFile = this.validate_fileupload(file.file.name);
				if(!validateFile){
					this.toastr.showWarning('Selected file is not supported.');
					this.uploadValidation = true;
				}else{
					this.uploadValidation = false;
				}
			}
			file.withCredentials = false;
		}

		this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
			console.log('ImageUpload:uploaded:', item);
			let json_response: any;
			if(response) {
				json_response = JSON.parse(response);
				console.log(json_response,status);
			}
			if(status < 200 || status > 299) {
				Helper.processErrorResponse(json_response, this.toastr);
			}
			if (item.isSuccess) {
				this._stopLoading();
				this.toastr.showSuccess('Successfully uploaded new draft');
				this.fileName = '';
        this.submitted = false;
        this.modalRef.hide();
				// this.addWriterForm.reset();
				this.resetUploader();
			} if (item.isError) {
				this._stopLoading();
				this.fileName = '';
				this.submitted = false;
				// this.addWriterForm.reset();
				this.resetUploader();
			}
		};
  }

  submitFile(){
    this.submitted = true;
    if(this.uploadValidation == true){
			this.toastr.showWarning('Selected file is not supported.');
			return;
		}
    if (this.fileName == '') {
			this.toastr.showWarning('Kindly select a file.');
		} else {
      this._startLoading();
			this.uploader.uploadAll();
		}
	}
	
	validate_fileupload(fileName)
    {
      let allowed_extensions = new Array("jpg","png", "pdf", "docx", "xlsx", "doc", "csv", "xls");
      let file_extension = fileName.split('.').pop().toLowerCase(); // split function will split the filename by dot(.), and pop function will pop the last element from the array which will give you the extension as well. If there will be no extension then it will return the filename.

      for(let i = 0; i <= allowed_extensions.length; i++)
      {
          if(allowed_extensions[i]==file_extension)
          {
              return true; // valid file extension
          }
      }

      return false;
  }

  selectFile(event) {
		this.fileName = event.target.files[0].name;
	}
  
  resetUploader() {
		this.mediaUploaderInput.nativeElement.value = "";
		this.uploader.clearQueue();
	}
 
  _startLoading() {
    this.loading = true;
  }
  
  _stopLoading() {
    this.loading = false;
  }

}