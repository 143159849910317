import { Component, TemplateRef, OnInit } from "@angular/core";

import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { CollegeService } from "src/app/core/services/college.service";
import { Helper } from "src/app/core/utilities/helpers";
import { CollegeProgramService } from "src/app/core/services/college.program.service";
import { Toastr } from "src/app/core/plugins/toastr/toastr";

@Component({
  selector: "edit-fields-modal",
  templateUrl: "./edit-fields.component.html",
  providers: [CollegeService, CollegeProgramService],
})
export class EditFieldsModal implements OnInit {
  studentId: any;
  collegeId: any;

  public fields: any = [];

  public visibleFields: any = [];
  public loading: boolean = false;
  public metaData: any;
  public collegeDetails: any;
  public is_mentor: boolean = false;

  constructor(
    public modalRef: BsModalRef,
    public collegeService: CollegeService,
    public collegeProgramService: CollegeProgramService,
    public toastr: Toastr
  ) {
    this.fields = [
      { id: "1", fieldName: "Name", value: "name" },
      { id: "2", fieldName: "Alliance", value: "alliance" },
      { id: "3", fieldName: "Description", value: "description" },
      { id: "4", fieldName: "FT Ranking", value: "ft_ranking" },
      { id: "5", fieldName: "US News Ranking", value: "us_news_ranking" },
      { id: "6", fieldName: "State", value: "state" },
      { id: "7", fieldName: "Links", value: "links" },
      { id: "8", fieldName: "Duration", value: "duration" },
      { id: "9", fieldName: "Stem Designated", value: "stem_designated" },
      { id: "10", fieldName: "GMAT Avg", value: "gmat_avg" },
      { id: "11", fieldName: "GRE Minimum", value: "gre_minimum" },
      { id: "12", fieldName: "AWA", value: "awa" },
      { id: "13", fieldName: "SAT", value: "sat" },
      { id: "14", fieldName: "SAT Two", value: "sat_two" },
      { id: "15", fieldName: "Tution Fees", value: "tution_fees" },
      { id: "16", fieldName: "Spring Intake", value: "spring_intake" },
      { id: "17", fieldName: "UG Early Decision", value: "ug_early_decision" },
      { id: "18", fieldName: "UG Early Action", value: "ug_early_action" },
      {
        id: "19",
        fieldName: "UG Spring Deadline",
        value: "ug_spring_deadline",
      },
      { id: "20", fieldName: "MS Fall Deadline", value: "ms_fall_deadline" },
      {
        id: "21",
        fieldName: "MS Spring Deadline",
        value: "ms_spring_deadline",
      },
      { id: "22", fieldName: "MBA R1 Deadline", value: "mba_r1_deadline" },
      { id: "23", fieldName: "MBA R2 Deadline", value: "mba_r2_deadline" },
      { id: "24", fieldName: "MBA R3 Deadline", value: "mba_r3_deadline" },
      {
        id: "25",
        fieldName: "MBA Spring Deadline",
        value: "mba_spring_deadline",
      },
      { id: "26", fieldName: "Class Size", value: "class_size" },
      { id: "27", fieldName: "Acceptance Rate", value: "acceptance_rate" },
      { id: "28", fieldName: "Living Expenses", value: "living_expenses" },
      {
        id: "29",
        fieldName: "Scholarship Offering",
        value: "scholarship_offering",
      },
      { id: "30", fieldName: "Loan Options", value: "loan_options" },
      {
        id: "31",
        fieldName: "Fifteen Years Acceptability",
        value: "fifteen_years_acceptability",
      },
      { id: "32", fieldName: "GMAT Score Reporting Code", value: "gmat_score" },
      { id: "33", fieldName: "GRE Score Reporting Code", value: "gre_score" },
      { id: "34", fieldName: "SAT Score Reporting Code", value: "sat_score" },
      {
        id: "35",
        fieldName: "TOEFL Score Reporting Code",
        value: "toefl_score",
      },
      { id: "36", fieldName: "IELTS Score", value: "ielts_score" },
      { id: "37", fieldName: "Application Fees", value: "application_fees" },
      {
        id: "38",
        fieldName: "School Transcripts",
        value: "school_transcripts",
      },
      {
        id: "39",
        fieldName: "Bachelor Transcripts",
        value: "bachelor_transcripts",
      },
      {
        id: "40",
        fieldName: "Master Transcripts",
        value: "master_transcripts",
      },
      {
        id: "41",
        fieldName: "Semester Marksheets",
        value: "semester_marksheets",
      },
      {
        id: "42",
        fieldName: "Bachelor Degree Certificate",
        value: "bachelor_degree_certificate",
      },
      {
        id: "43",
        fieldName: "Master Degree Certificate",
        value: "master_degree_certificate",
      },
      { id: "44", fieldName: "WES Evaluation", value: "wes_evaluation" },
      {
        id: "45",
        fieldName: "UG Regular Deadline",
        value: "ug_regular_deadline",
      },
      { id: "46", fieldName: "TOEFL", value: "toefl" },
      {
        id: "47",
        fieldName: "PHD Spring Deadline",
        value: "phd_spring_deadline",
      },
      { id: "48", fieldName: "PHD Fall Deadline", value: "phd_fall_deadline" },
    ];
  }

  ngOnInit() {
    this.is_mentor = window.location.href.includes("mentor");

    this._fetchCurrentFeildsFromServer();
  }

  _fetchCurrentFeildsFromServer(params = {}) {
    this._startLoading();
    params = Helper.cloneObj(params, {
      includes: "college_program,program_checklists,student,counsellors",
    });
    if (this.is_mentor) {
      this.collegeService
        .getMentorStudentProgramDetails(this.studentId, this.collegeId, params)
        .subscribe(
          (res) => {
            console.log("=========College Details=========>", res);
            this.collegeDetails = res.data;
            this._formatMetaData();
          },
          (error) => {
            this._stopLoading();
            console.log(error);
          }
        );
    } else {
      this.collegeService
        .getCounsellorStudentProgramDetails(
          this.studentId,
          this.collegeId,
          params
        )
        .subscribe(
          (res) => {
            console.log("=========College Details=========>", res);
            this.collegeDetails = res.data;
            this._formatMetaData();
          },
          (error) => {
            this._stopLoading();
            console.log(error);
          }
        );
    }
  }

  _formatMetaData() {
    let metaData = Helper.getObjProp(
      this.collegeDetails,
      "college_program.meta_data"
    );
    const arr = [];
    Object.keys(metaData).map((data) => {
      if (data == "type" || data == "type_text") {
        //do nothing
      } else {
        arr.push(data);
      }
    });
    this.visibleFields = arr;
    this._stopLoading();
  }

  checkFields(event) {
    if (event.target.checked) {
      this.visibleFields.push(event.target.value);
    } else {
      let tempShortlistedColleges = this.visibleFields;
      this.visibleFields = [];
      tempShortlistedColleges.forEach((element) => {
        if (element == event.target.value) {
          // Do Nothing
        } else {
          this.visibleFields.push(element);
        }
      });
    }

    console.log(this.visibleFields);
  }

  submit() {
    this._startLoading();
    let tempObject = {
      visible_fields: this.visibleFields,
    };
    if (this.is_mentor) {
      this.collegeProgramService
        .updateShortlistedCollegeProgramByMentor(
          this.studentId,
          this.collegeId,
          tempObject
        )
        .subscribe(
          (res) => {
            this.toastr.showSuccess("Successfully updated the fields");
            this.modalRef.hide();
            this._stopLoading();
          },
          (error) => {
            this._stopLoading();
          }
        );
    } else {
      this.collegeProgramService
        .updateShortlistedCollegeProgram(
          this.studentId,
          this.collegeId,
          tempObject
        )
        .subscribe(
          (res) => {
            this.toastr.showSuccess("Successfully updated the fields");
            this.modalRef.hide();
            this._stopLoading();
          },
          (error) => {
            this._stopLoading();
          }
        );
    }
  }

  _startLoading() {
    this.loading = true;
  }

  _stopLoading() {
    this.loading = false;
  }
}
