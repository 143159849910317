// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    // apiKey: "AIzaSyCmyA08UlgqGilEzgS-psFuM00sUEUdtMA",
    // authDomain: "fir-chat-app-493fa.firebaseapp.com",
    // databaseURL: "https://fir-chat-app-493fa.firebaseio.com",
    // projectId: "fir-chat-app-493fa",
    // storageBucket: "fir-chat-app-493fa.appspot.com",
    // messagingSenderId: "74309098776"
    apiKey: "AIzaSyDA-YONGWwkqaJJjaH6N9WstoYVD6ddLgI",
    authDomain: "jamboree-dev-231510.firebaseapp.com",
    databaseURL: "https://jamboree-dev-231510.firebaseio.com",
    projectId: "jamboree-dev-231510",
    storageBucket: "jamboree-dev-231510.appspot.com",
    messagingSenderId: "559103923772",
  },
  temp_doc_path: "",
  server: {
    // baseUrl: "http://localhost:3000",
     // baseUrl: "http://localhost:6521",
    // baseUrl: "https://jmbadmin.squareboat.info",
    // baseUrl: "https://api.jamboree.squareboat.info",
    baseUrl: "https://api.jams.jamboreeeducation.com",
    apiVersionPath: "/api/v1",
  },
  aws: {
    access_key_id: "AKIA4QWZJGHENEBX7PWC",
    secret_access_key: "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",
  },
  appointment: {
    defaultMeetingLink: "https://meet.com",
    meetingPassword: "123456",
  },
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
