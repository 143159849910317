import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { Toastr } from "../../../plugins/toastr/toastr";
import { StudentService } from "src/app/core/services/student.service";

@Component({
  selector: "update-student-college-result-modal",
  templateUrl: "./update-student-college-result.component.html",
  providers: [StudentService, Toastr],
})
export class UpdateStudentCollegeResultModal implements OnInit {
  public loading: boolean = false;
  studentId: any;
  collegeId: any;
  currentResult: any;
  collegeType: any;
  public is_Mentor: boolean = false;

  constructor(
    public studentService: StudentService,
    public toastr: Toastr,
    public modalRef: BsModalRef
  ) {}

  ngOnInit() {
    console.log(this.studentId);
    this.is_Mentor = window.location.href.includes("mentor");
  }

  submit() {
    console.log(this.currentResult);
    if (!this.currentResult) {
      this.toastr.showWarning(
        "Select atleast one state, for updating the result."
      );
    } else {
      this._startLoading();
      let formData;
      if (this.collegeType == 1) {
        formData = {
          result: this.currentResult,
        };
      } else {
        formData = {
          status: this.currentResult,
        };
      }
      if (this.is_Mentor) {
        this.studentService
          .updateMentorStudentCollegeResult(
            this.studentId,
            this.collegeId,
            formData
          )
          .subscribe(
            (res) => {
              this.toastr.showSuccess("Successfully updated result");
              this._stopLoading();
              this.modalRef.hide();
            },
            (error) => {
              this._stopLoading();
            }
          );
      } else {
        this.studentService
          .updateCounsellorStudentCollegeResult(
            this.studentId,
            this.collegeId,
            formData
          )
          .subscribe(
            (res) => {
              this.toastr.showSuccess("Successfully updated result");
              this._stopLoading();
              this.modalRef.hide();
            },
            (error) => {
              this._stopLoading();
            }
          );
      }
    }
  }

  check(e) {
    this.currentResult = e.target.value;
  }

  _startLoading() {
    this.loading = true;
  }

  _stopLoading() {
    this.loading = false;
  }
}
