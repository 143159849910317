export const testingOptions = ["SAT", "ACT", "AP Exam", "Others"];

export const englishProficiencyOptions = [
  "TOEFL",
  "IELTS",
  "Duolingo",
  "Others",
];

export const academicsOptions = [
  "A Levels",
  "CBSE",
  "IBDP",
  "ICSE",
  "IGCSE",
  "State Board",
  "Others",
  "HSC",
];

export const cocirricularOptions = [
  "Certification / Competition",
  "Clever Harvey Junior MBA",
  "Lumiere Research",
  "Online Courses",
  "Purple Tutor Coding",
  "Research Papers",
  "Summer School",
  "Olympiads",
  "Pioneer Research Programme",
  "Euclid",
  "Girls Who Code",
  "Cambridge Future Scholar Research Program",
  "Others",
];

export const extracurricularActivitiesOptions = [
  "Art",
  "Blog",
  "Leadership Positions at School",
  "Music",
  "Sport",
  "Vlog",
  "Marathons",
  "Others",
];

export const communityServiceOptions = [
  "Bridges Academy",
  "Education",
  "Environment / Sustainability",
  "Financial Services / Literacy",
  "Healthcare",
  "Rehabilitation",
  "TribesForGood",
  "Goonj",
  "Read a Story",
  "iVolunteer",
  "Others",
];

export const projectOptions = [
  "Economics / Business",
  "Humanities",
  "STEM",
  "Shadowing internships with a doctor/dentist",
  "Others",
];

export const internshipOptions = [
  "Belong",
  "Finance",
  "Pre-Med",
  "Social Sector",
  "Technology",
  "Others",
  "Talerang",
  "Hello Intern",
  "Alacrous",
];
