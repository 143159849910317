import { environment } from "src/environments/environment";

export default {
  documents: {
    type: [
      { id: 1, name: "CV" },
      { id: 2, name: "SOP" },
      { id: 3, name: "LOR" },
      { id: 4, name: "ESSAY" },
      { id: 5, name: "Personal Statement" },
      { id: 6, name: "Research Statement" },
      { id: 7, name: "Letter of Motivation" },
      { id: 8, name: "VISA SOP" },
    ],
  },

  aws: {
    s3: {
      accessKeyId: environment.aws.access_key_id,
      secretAccessKey: environment.aws.secret_access_key,
      bucket: "jamboree",
      folder: {
        chat: "uploads/documents/chat",
        application_materials: "uploads/documents/applicationMaterials",
      },
    },
  },

  mime: {
    type: ["image/png", "image/jpg", "image/jpeg"],
  },

  bulkUpload: {
    mime: {
      type: ["text/csv"],
    },
  },
  databaseNotifications: {
    types: {
      studentUploadedDraft: {
        url: "/writer/students/individual-student/{ studentId }/colleges/{ collegeId }/documents/{ documentId }/student-college-drafts",
      },
      studentFilledCollegeLoginDetails: {
        url: {
          forAdmin:
            "/manager/students/{ studentId }/individual-student-college/{ collegeId }/student-college-review",
          forCounsellor:
            "/counsellor/students/{ studentId }/individual-student-college/{ collegeId }/student-college-review",
        },
      },
      studentMarkedDraftFinal: {
        url: "/writer/students/individual-student/{ studentId }/colleges/{ collegeId }/documents/{ documentId }/student-college-drafts",
      },
      newStudentAssigned: {
        url: "/counsellor/students/individual-student/{ studentId }/student-profile",
      },
      collegeApprovedByStudent: {
        url: "/counsellor/students/{ studentId }/individual-student-college/{ collegeId }/student-college-info",
      },
      collegesAssignedToCounsellor: {
        url: "/counsellor/colleges",
      },
      newStudentDocumentAssigned: {
        url: "/writer/students/individual-student/{ studentId }/student-college-documents",
      },
      newStudentFilledEnrolmentDetails: {
        url: "/manager/students/individual-student/{ studentId }/student-profile",
      },
      counsellorApprovesStudentsCollege: {
        url: {
          forAdmin:
            "/manager/students/{ studentId }/individual-student-college/{ collegeId }/student-college-info",
          forWriterManager:
            "/writer-manager/students/{ studentId }/individual-student-college/{ collegeId }/student-college-info",
        },
      },
      newStudentAssignedToInterviewTrainer: {
        url: "/interview-trainer/students/{ studentId }/individual-student-college/{ collegeId }/student-college-interview-assistance?collegeType=0",
        urlWithQuery:
          "/interview-trainer/students/{ studentId }/individual-student-college/{ collegeId }/student-college-interview-assistance?collegeType=1",
      },
      studentUpdatedInterviewResult: {
        url: "/interview-trainer/students/{ studentId }/individual-student-college/{ collegeId }/student-college-interview-assistance?collegeType=0",
        urlWithQuery:
          "/interview-trainer/students/{ studentId }/individual-student-college/{ collegeId }/student-college-interview-assistance?collegeType=1",
      },
      newStudentAssignedToVisaCounsellor: {
        url: "visa-counsellor/students/{ studentId }/individual-student-college/{ collegeId }/student-college-visa-counselling?collegeType=0",
        urlWithQuery:
          "visa-counsellor/students/{ studentId }/individual-student-college/{ collegeId }/student-college-visa-counselling?collegeType=1",
      },
      studentUpdatedVisaResult: {
        url: "visa-counsellor/students/{ studentId }/individual-student-college/{ collegeId }/student-college-visa-counselling?collegeType=0",
        urlWithQuery:
          "visa-counsellor/students/{ studentId }/individual-student-college/{ collegeId }/student-college-visa-counselling?collegeType=1",
      },
      studentRequiresInterviewAssistance: {
        url: {
          forAdmin:
            "manager/students/{ studentId }/individual-student-college/{ collegeId }/student-college-info",
          forInterviewManager:
            "interview-trainer-manager/students/{ studentId }/individual-student-college/{ collegeId }/student-college-info",
        },
      },
      studentRequiresVisaAssistance: {
        url: {
          forAdmin:
            "manager/students/{ studentId }/individual-student-college/{ collegeId }/student-college-info",
          forVisaManager:
            "visa-counsellor-manager/students/{ studentId }/individual-student-college/{ collegeId }/student-college-info",
        },
      },
      // new Url add ....
      newAppointmentCreatedByStudent: {
        url: {
          forCounsellor:
            "counsellor/appointments",
        },
      },
     
   
      requestToAssignWriterByCounsellor: {
        url: {
          forCounsellingManager:
            "/writer-manager/students/individual-student/{ studentId }/student-colleges",
            
         
        },
      },
      shortlistedProgramUpdatedByCounsellorManager: {
        url: {
          forCounsellor:
            "counsellor/colleges",
         
        },
      },
      collegesShortlistedByCounsellorManager: {
        url: {
          forCounsellor:
            "counsellor/students/individual-student/{ studentId }/student-colleges",
          
        },
      },
      collegeDisapprovedByCounsellorManager: {
        url: {
          forCounsellor:
          "counsellor/students/individual-student/{ studentId }/student-colleges",
         
        },
      },
      collegeApprovedByCounsellorManager: {
        url: {
          forCounsellor:
          "counsellor/students/individual-student/{ studentId }/student-colleges",
          
        },
      },
      collegeDeletedByCounsellorManager: {
        url: {
          forCounsellor:
          "counsellor/students/individual-student/{ studentId }/student-colleges",
          
        },
      },
      collegesSentForQualityCheck: {
        url: {
          forCounsellingManager:
            "counselling-manager/students/individual-student/{ studentId }/student-colleges",
        },
      },
      collegeProgramSentForReview:{
        url: {
          forCounsellingManager:
          "counselling-manager/counsellors/individual-counsellor/{ counsellorId }/counsellor-colleges",
        }
      },
      enrollmentFormUpdated:{
        url: {
          forCounsellor:
          "/counsellor/students/individual-student/{ studentId }/student-profile",
        }
      },
     
    },
  },
  notificationsWithProfilePic: [1, 2, 3, 4, 5, 6, 10, 11],
  localStorage: {
    objectNames: ["authUser", "config", "adminFcmToken"],
  },
};
