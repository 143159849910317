import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireMessaging } from 'angularfire2/messaging';
import { mergeMapTo } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs'
import { AngularFireDatabase } from 'angularfire2/database';
import { HttpClient } from '@angular/common/http';
import { ApiRoutes } from '../config/api-routes';
import { Storage } from '../utilities/storage';
import { Toastr } from '../plugins/toastr/toastr';
import { Helper } from '../utilities/helpers';

@Injectable()
export class MessagingService {

  currentMessage = new BehaviorSubject(null);

  constructor(
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging,
    private http: HttpClient,
    private apiRoutes: ApiRoutes,
    public storage: Storage,
    public toastr: Toastr
    ) {
    this.angularFireMessaging.messaging.subscribe(
      (_messaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )
  }

  /**
   * update token in firebase database
   * 
   * @param userId userId as a key 
   * @param token token as a value
   */
  updateToken(userId, token) {
    // we can change this function to request our backend service
    // this.angularFireAuth.authState.pipe(take(1)).subscribe(
    //   () => {
    //     const data = {};
    //     data[userId] = token
    //     this.angularFireDB.object('fcmTokens/').update(data)
    //   })

    let formData = {
      device_id: null,
      device_type: 3,
      token: token
    }
    this.storage.set('adminFcmToken', token )
    let url = this.apiRoutes.getUpdateTokenUrl(userId);
        return this.http.post(url, formData)
        .map((res: any) => {
            return res;
        });

  }

  /**
   * request permission for notification from firebase cloud messaging
   * 
   * @param userId userId
   */
  requestPermission(userId) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token);
        if(Helper.isNull(token)){
          console.log('Unable to get token');
        }else{
          this.updateToken(userId, token).subscribe();
        }
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload: any) => {
        this.toastr.showInfo(payload.notification.body, payload.notification.title)
        console.log("new message received. ", payload);
        this.currentMessage.next(payload);
      })
  }
}