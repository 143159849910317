import {Component, TemplateRef} from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
  selector: 'update-tasks-modal',
  templateUrl: './update-tasks.component.html'
})
export class UpdateTasksModal {

  tasks: Array<{ id: any, taskName: any }>;

  constructor(public modalRef: BsModalRef) {

    this.tasks = [
      {id: '1', taskName: 'Pariatur officia pariatur excepteur exercitation reprehenderit do.'},
      {id: '2', taskName: 'Eiusmod consequat et eiusmod duis cillum velit dolore pariatur labore dolore culpa sit amet.'},
      {id: '3', taskName: 'Sint voluptate ullamco incididunt magna consequat id excepteur nulla voluptate incididunt.'},
      {id: '4', taskName: 'Pariatur officia pariatur excepteur exercitation reprehenderit do.'},
      {id: '5', taskName: 'Pariatur officia pariatur excepteur exercitation reprehenderit do.'},
      {id: '6', taskName: 'Eiusmod consequat et eiusmod duis cillum velit dolore pariatur labore dolore culpa sit amet.'},
      {id: '7', taskName: 'Sint voluptate ullamco incididunt magna consequat id excepteur nulla voluptate incididunt.'},
      {id: '8', taskName: 'Pariatur officia pariatur excepteur exercitation reprehenderit do.'},
      {id: '9', taskName: 'Pariatur officia pariatur excepteur exercitation reprehenderit do.'},
      {id: '10', taskName: 'Eiusmod consequat et eiusmod duis cillum velit dolore pariatur labore dolore culpa sit amet.'},
      {id: '11', taskName: 'Sint voluptate ullamco incididunt magna consequat id excepteur nulla voluptate incididunt.'},
      {id: '12', taskName: 'Pariatur officia pariatur excepteur exercitation reprehenderit do.'},
    ]

  }

}