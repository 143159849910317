import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Ng bootstrap
import { BsDropdownModule, TabsModule, TooltipModule, ModalModule, BsDatepickerModule, TypeaheadModule, TimepickerModule, CollapseModule, AccordionModule } from 'ngx-bootstrap';

// Form Wizard Module
import { FormWizardModule } from 'angular2-wizard/dist';

// Ng2 Select
import { SelectModule } from 'ng2-select';
import { Storage } from './core/utilities/storage';
import { ControlMessages } from './core/utilities/validator/control-messages';
import { Toastr } from './core/plugins/toastr/toastr';
import { ToastrService } from 'ngx-toastr';
import { LoaderSpinner } from './core/directives/loader-spinner.directive';
import { FillerBlock } from './core/directives/filler-block.directive';
import { DisableControlDirective } from './core/directives/disable-control.directive';
import { FilterPipe } from './core/utilities/filter.pipe';
import { LoadingSpinnerComponent } from './core/components/loading-spinner/loading-spinner.component';
import { SideNavBar } from './core/directives/side-nav-bar/side-nav-bar.directive';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		BsDropdownModule.forRoot(),
		TabsModule.forRoot(),
		TooltipModule.forRoot(),
		ModalModule.forRoot(),
		BsDatepickerModule.forRoot(),
		TypeaheadModule.forRoot(),
		TimepickerModule.forRoot(),
		AccordionModule.forRoot(),
		SelectModule,
		FormWizardModule,
		CollapseModule.forRoot(),
	],
	declarations: [
		ControlMessages,
		LoaderSpinner,
		LoadingSpinnerComponent,
		SideNavBar,
		FillerBlock,
		FilterPipe,
		DisableControlDirective
	],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		BsDropdownModule,
		TabsModule,
		TooltipModule,
		ModalModule,
		AccordionModule,
		BsDatepickerModule,
		TypeaheadModule,
		TimepickerModule,
		SelectModule,
		FormWizardModule,
		ControlMessages,
		LoaderSpinner,
		SideNavBar,
		FillerBlock,
		FilterPipe,
		LoadingSpinnerComponent,
		DisableControlDirective,
		CollapseModule,
	],
	providers: [
		Storage,
		Toastr,
		ToastrService,
	]
})
export class SharedModule {
}
